<template>
  <a-layout class="main__container">
    <div class="banner">
      <div class="cen">
        <div class="le">
          <div class="le_tit">
            <div class="name">潮流艺术</div>
            <div class="en">Trend of the art</div>
            <div class="black"></div>
          </div>
          <div class="txt">
            捕捉日常、多样、特色的新潮文化艺术
          </div>
        </div>
        <div class="ri">
          <img src="../assets/home/icon_img44.png" alt="" />
        </div>
      </div>
    </div>
    <div class="card_one">
      <div class="tit">
        <img src="../assets/home/icon_img31.png" alt="" @click="back()" />
        <div class="tit-r">
          <div class="name">潮流艺术</div>
          <div class="en">Trend of the art</div>
        </div>
      </div>

      <div class="list">
        <div
          class="item"
          v-for="(item, index) in 20"
          :key="index"
          :style="!isShow && index > 11 ? 'display:none;' : ''"
        >
          <img src="../assets/home/fj2.jpg" alt="" />
          <div class="item_t">作品名称</div>
        </div>
      </div>
      <div
        class="showAll"
        :class="!isShow ? '' : 'active'"
        @click="isShow = !isShow"
      >
        更多作品
        <img src="../assets/home/icon_img26.png" alt="" />
      </div>
    </div>
  </a-layout>
</template>

<script>
export default {
  name: "TrendyArt",
  data() {
    return {
      isShow: false
    };
  },
  methods: {
    back() {
      this.$router.back();
    }
  }
};
</script>

<style scoped lang="less">
.main__container {
  background-color: #eef0f3;
  padding-top: 72px;

  .banner {
    width: 100%;
    height: 420px;
    background: #f878a0;

    .cen {
      width: 1150px;
      height: 314px;
      margin: 52px auto 0;
      padding: 0 72px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .le {
        width: 387px;

        .le_tit {
          .name {
            font-weight: 600;
            font-size: 32px;
            color: rgba(0, 0, 0, 0.85);
          }

          .en {
            font-weight: 500;
            font-size: 18px;
            color: rgba(0, 0, 0, 0.85);
          }

          .black {
            width: 62px;
            height: 6px;
            background: rgba(0, 0, 0, 0.85);
            margin-top: 16px;
          }
        }

        .txt {
          margin-top: 12px;
          font-weight: 400;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 24px;
        }
      }

      .ri {
        img {
          width: 338px;
          height: 314px;
          display: block;
        }
      }
    }
  }
  .card_one {
    width: 1150px;
    margin: 58px auto;

    .tit {
      display: flex;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
        margin-right: 16px;
        cursor: pointer;
      }

      .tit-r {
        .name {
          font-weight: 500;
          font-size: 20px;
          color: #232324;
        }

        .en {
          font-weight: 500;
          font-size: 12px;
          color: rgba(35, 35, 36, 0.45);
        }
      }
    }

    .list {
      margin-left: -34px;
      display: flex;
      flex-wrap: wrap;
      .item {
        margin-left: 34px;
        margin-top: 32px;
        width: 262px;
        cursor: pointer;
        img {
          display: block;
          width: 262px;
          height: 196px;
        }
        .item_t {
          width: 262px;
          height: 44px;
          background: rgba(0, 0, 0, 0.03);
          line-height: 44px;
          padding: 0 12px;
          font-weight: 500;
          font-size: 12px;
          color: #000000;
          display: -webkit-box;
          -webkit-line-clamp: 1; /* 设置显示的行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .showAll {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    margin: 40px auto 0px;
    width: 150px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
      margin-left: 6px;
    }
    &.active {
      img {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
