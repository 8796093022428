<template>
  <a-layout class="main__container">
    <div class="banner">
      <img src="../assets/home/icon_img29.png" alt="" />
    </div>
    <div class="tit">
      <div class="tit_name">
        赛事资讯
      </div>
      <div class="tit_en">
        The relevant information
      </div>
    </div>
    <div class="list">
      <div
        class="item"
        v-for="(item, index) in 3"
        :key="index"
        @click="detail()"
      >
        <img src="../assets/home/icon_img30.png" alt="" />
        <div class="info">
          <div class="info_t">
            这是一个标题，什么是活动，什么是设计什么是的标题！
          </div>
          <div class="info_txt">
            <div class="txt">
              杭州某某主办单这是一个标题，什么是活动，什么是设计什么是设计什位；这是一个标题，什么是活动，什么是设计什么是设计什
              杭州某某主办单这是一个标题，什么是活动，什么是设计什么是设计什位；这是一个标题，什么是活动，什么是设计什么是设计什
              杭州某某主办单这是一个标题，什么是活动，什么是设计什么是设计什位；这是一个标题，什么是活动，什么是设计什么是设计什
              杭州某某主办单这是一个标题，什么是活动，什么是设计什么是设计什位；这是一个标题，什么是活动，什么是设计什么是设计什
            </div>
          </div>
          <div class="info_b">
            <div class="name">杭州某某主办单位</div>
            <div class="date">2021年 10月2日</div>
          </div>
        </div>
      </div>
    </div>
  </a-layout>
</template>

<script>
export default {
  name: "GamePageDetail",
  data() {
    return {};
  },
  methods: {
    detail() {
      this.$router.push({
        name: "InformationDetail"
      });
    }
  }
};
</script>

<style scoped lang="less">
.main__container {
  background-color: #eef0f3;
  .banner {
    img {
      width: 100%;
    }
  }
  .tit {
    margin-top: 52px;
    text-align: center;
    .tit_name {
      font-weight: 600;
      font-size: 24px;
      color: rgba(0, 0, 0, 0.85);
    }
    .tit_en {
      font-weight: 600;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .list {
    width: 1150px;
    margin: 0 auto 100px;
    .item {
      display: flex;
      align-items: center;
      margin-top: 32px;
      cursor: pointer;
      img {
        width: 226px;
        height: 168px;
        object-fit: cover;
      }
      .info {
        padding: 12px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 168px;
        .info_t {
          font-weight: 600;
          font-size: 18px;
          color: rgba(0, 0, 0, 0.85);
          display: -webkit-box;
          -webkit-line-clamp: 1; /* 设置显示的行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .info_txt {
          font-weight: 400;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
          flex: 1;
          margin-top: 12px;
          .txt {
            display: -webkit-box;
            -webkit-line-clamp: 3; /* 设置显示的行数 */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .info_b {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 400;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }
  }
}
</style>
