<template>
  <a-layout class="main__container">
    <div class="banner">
      <img src="../assets/home/icon_img32.png" alt="" />
    </div>
    <div class="card_one">
      <div class="tit">
        <img src="../assets/home/icon_img34.png" alt="" />
      </div>
      <div class="com">
        <div class="le">
          <img src="../assets/home/icon_img35.png" alt="" />
        </div>
        <div class="ri">
          <div class="item" @click="classDetail()">
            <div class="item_l">
              <div class="yuan"></div>
              <div class="name">绘画类</div>
            </div>
            <div class="item_r">
              <img src="../assets/home/icon_img36.png" alt="" />
            </div>
          </div>
          <div class="item" @click="classDetail()">
            <div class="item_l">
              <div class="yuan"></div>
              <div class="name">立体造型类</div>
            </div>
            <div class="item_r">
              <img src="../assets/home/icon_img37.png" alt="" />
            </div>
          </div>
          <div class="item" @click="classDetail()">
            <div class="item_l">
              <div class="yuan"></div>
              <div class="name">书法类</div>
            </div>
            <div class="item_r">
              <img src="../assets/home/icon_img38.png" alt="" />
            </div>
          </div>
          <div class="item" @click="classDetail()">
            <div class="item_l">
              <div class="yuan"></div>
              <div class="name">数字创意类</div>
            </div>
            <div class="item_r">
              <img src="../assets/home/icon_img39.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <img src="../assets/home/icon_img33.png" class="bom" alt="" />
    </div>
    <div class="card_two">
      <div class="tit">
        <div class="name_z">
          课程推荐
        </div>
        <div class="name_en">
          Course recommendation
        </div>
        <div class="tip">
          具有社会特色、优质的公益性课程
        </div>
      </div>
      <div class="list">
        <div
          class="item"
          v-for="(item, index) in 9"
          :key="index"
          @click="detail()"
        >
          <div class="item_t">
            <img class="bg" src="../assets/home/fj3.jpg" alt="" />
            <img class="start" src="../assets/home/icon_img40.png" alt="" />
          </div>
          <div class="item_b">
            如何起一个简洁易懂的标题
          </div>
        </div>
      </div>
    </div>
    <div class="card_bom">
      <img src="../assets/home/icon_img41.png" alt="" />
    </div>
  </a-layout>
</template>

<script>
export default {
  name: "PublicWelfareClassList",
  data() {
    return {};
  },
  methods: {
    classDetail() {
      this.$router.push({
        name: "PublicWelfareClass"
      });
    },
    detail() {
      this.$router.push({
        name: "PublicWelfareClassDetail"
      });
    }
  }
};
</script>

<style scoped lang="less">
.main__container {
  background-color: #eef0f3;
  padding-top: 72px;
  .banner {
    img {
      width: 100%;
    }
  }
  .card_one {
    width: 100%;
    height: 820px;
    background: #faf9fa;
    margin: 0 auto;
    position: relative;
    .tit {
      width: 1150px;
      margin: 20px auto 42px;
      img {
        width: 110px;
        height: 34px;
      }
    }
    .com {
      width: 1150px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 9;
      .le {
        width: 112px;
        height: 444px;
        img {
          width: 112px;
          height: 444px;
          display: block;
        }
      }
      .ri {
        display: flex;
        flex-wrap: wrap;
        margin-top: -32px;
        .item {
          cursor: pointer;
          margin-left: 28px;
          margin-top: 32px;
          width: 484px;
          height: 206px;
          background: #ffffff;
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
          display: flex;
          align-items: center;
          .item_l {
            width: 70px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .yuan {
              width: 22px;
              height: 22px;
              border: 2px solid #000000;
              border-radius: 50%;
            }
            .name {
              margin-top: 8px;
              writing-mode: vertical-rl; /* 文字从右向左垂直排列 */
              text-orientation: upright; /* 文字保持正常方向 */
              font-weight: 500;
              font-size: 18px;
              color: rgba(0, 0, 0, 0.85);
            }
          }
          img {
            width: 414px;
            height: 206px;
          }
        }
      }
    }
    .bom {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
    }
  }
  .card_two {
    width: 920px;
    margin: -190px auto 290px;
    position: relative;
    z-index: 9;
    .tit {
      margin: 0 auto;
      width: 235px;
      text-align: center;
      .name_z {
        font-weight: 600;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.85);
      }
      .name_en {
        font-weight: 600;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.85);
        border-bottom: 2px solid;
      }
      .tip {
        font-weight: 300;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      margin-left: -64px;
      .item {
        margin-left: 64px;
        margin-top: 64px;
        width: 264px;
        .item_t {
          border-radius: 0px 0px 25px 0px;
          border: 1px solid #979797;
          width: 264px;
          height: 264px;
          position: relative;
          cursor: pointer;
          overflow: hidden;
          .bg {
            width: 100%;
            height: 100%;
            display: block;
          }
          .start {
            position: absolute;
            width: 58px;
            height: 58px;
            left: 50%;
            top: 50%;
            margin-left: -29px;
            margin-top: -29px;
            z-index: 2;
          }
        }
        .item_b {
          margin-top: 20px;
          font-weight: 500;
          font-size: 14px;
          color: #000000;
          display: -webkit-box;
          -webkit-line-clamp: 1; /* 设置显示的行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .card_bom {
    img {
      width: 100%;
    }
  }
}
</style>
