<template>
  <a-layout class="main__container">
    <div class="tit">
      <img src="../assets/home/icon_img31.png" alt="" @click="back()" />
      <div class="tit-r">
        <div class="name">世界名画</div>
        <div class="en">The world famous paintings</div>
      </div>
    </div>
    <div class="list">
      <div class="list_c">
        <div
          class="item"
          v-for="(item, index) in 7"
          :key="index"
          @click="detail()"
        >
          <img src="../assets/home/fj2.jpg" alt="" />
          <div class="item_t">课程{{ index + 1 }}</div>
        </div>
      </div>
    </div>
  </a-layout>
</template>

<script>
export default {
  name: "PublicWelfareClass",
  data() {
    return {};
  },
  methods: {
    detail() {
      this.$router.push({
        name: "PublicWelfareClassDetail"
      });
    },
    back() {
      this.$router.back();
    }
  }
};
</script>

<style scoped lang="less">
.main__container {
  background-color: #eef0f3;
  padding-top: 72px;
  .tit {
    display: flex;
    align-items: center;
    width: 1150px;
    margin: 32px auto;
    img {
      width: 24px;
      height: 24px;
      margin-right: 16px;
      cursor: pointer;
    }
    .tit-r {
      .name {
        font-weight: 500;
        font-size: 20px;
        color: #232324;
      }
      .en {
        font-weight: 500;
        font-size: 12px;
        color: rgba(35, 35, 36, 0.45);
      }
    }
  }
  .list {
    width: 1150px;
    margin: 0 auto 100px;
    .list_c {
      display: flex;
      flex-wrap: wrap;
      margin-left: -72px;
      .item {
        margin-left: 72px;
        margin-top: 48px;
        cursor: pointer;
        img {
          width: 334px;
          height: 334px;
          object-fit: cover;
        }
        .item_t {
          margin-top: 24px;
          font-weight: 500;
          font-size: 18px;
          color: #000000;
          display: -webkit-box;
          -webkit-line-clamp: 1; /* 设置显示的行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
