<template>
  <a-layout class="main__container">
    <div class="banner">
      <a-carousel>
        <div class="item">
          <img src="../assets/home/fj1.webp" alt="" />
          <div class="tit">
            数字艺术家以月光为灵感，创作震撼3D光影雕塑，革新视觉艺术边界
          </div>
        </div>
        <div class="item">
          <img src="../assets/home/fj2.jpg" alt="" />
          <div class="tit">
            "跨界合作：时尚设计师与AI携手，打造未来感十足的虚拟时装秀"
          </div>
        </div>
      </a-carousel>
    </div>
    <div class="content">
      <div class="tabList">
        <div
          class="tab"
          :class="tabActive === '' ? 'active' : ''"
          @click="setTab('')"
        >
          全部
        </div>
        <div
          class="tab"
          :class="tabActive === '1' ? 'active' : ''"
          @click="setTab('1')"
        >
          征集中
        </div>
        <div
          class="tab tab_end"
          :class="tabActive === '2' ? 'active' : ''"
          @click="setTab('2')"
        >
          已结束
        </div>
      </div>
      <div class="dataList">
        <div
          class="item"
          v-for="(item, index) in 6"
          :key="index"
          @click="detail()"
        >
          <img src="../assets/home/game_img.png" alt="" />
          <div class="item_info">
            <div class="info_top">
              <div class="tag">征集</div>
              <div class="title">这是一个动态的标题</div>
            </div>
            <div class="date">2021年 10月2日——2021年 11月2日</div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <a-pagination v-model="current" :total="50" show-less-items />
      </div>
    </div>
  </a-layout>
</template>

<script>
export default {
  name: "GamePage",
  data() {
    return {
      tabActive: "",
      current: 1
    };
  },
  methods: {
    setTab(tab) {
      this.tabActive = tab;
    },
    detail() {
      this.$router.push({
        name: "GamePageDetail"
      });
    }
  }
};
</script>

<style scoped lang="less">
.main__container {
  background-color: #eef0f3;
  padding-top: 72px;

  .banner {
    width: 1150px;
    margin: 0 auto;

    /deep/ .slick-dots-bottom {
      bottom: 24px;
      display: flex !important;
      padding-left: 24px;
    }

    /deep/ .slick-dots {
      li {
        button {
          width: 29px !important;
        }
      }
    }

    .item {
      width: 1150px;
      position: relative;
      height: 404px;

      img {
        width: 1150px;
        height: 404px;
      }

      .tit {
        position: absolute;
        left: 24px;
        bottom: 36px;
        color: #fff;
        font-size: 22px;
        right: 24px;
      }
    }
  }
  .tabList {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    .tab {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      cursor: pointer;
      margin-right: 40px;
    }
    .active {
      font-weight: 500;
    }
    .tab_end {
      margin: 0;
    }
  }
  .dataList {
    width: 1150px;
    margin: 16px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: 566px;
      margin-top: 18px;
      background-color: #fff;
      cursor: pointer;
      img {
        width: 566px;
        height: 318px;
        object-fit: cover;
      }
      .item_info {
        padding: 16px;
        .info_top {
          display: flex;
          align-items: center;
          .tag {
            width: 36px;
            height: 21px;
            background: #dddddd;
            border-radius: 11px;
            text-align: center;
            line-height: 21px;
            font-size: 11px;
            color: rgba(0, 0, 0, 0.65);
            &.active {
              color: rgba(0, 0, 0, 0.25);
            }
          }
          .title {
            font-size: 18px;
            color: rgba(0, 0, 0, 0.85);
            margin-left: 12px;
            flex: 1;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* 设置显示的行数 */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .date {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.45);
            margin-top: 2px;
          }
        }
      }
    }
  }
  .pagination {
    width: 1150px;
    margin: 28px auto 104px;
    display: flex;
    justify-content: center;
  }
}
/deep/.ant-pagination-item-active {
  border-color: #08a782;
  a {
    color: #08a782;
  }
}
/deep/.ant-pagination-item:hover,
/deep/.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #08a782;
  a {
    color: #08a782;
  }
  .anticon {
    color: #08a782;
  }
}
/deep/.ant-pagination-prev:focus .ant-pagination-item-link,
/deep/.ant-pagination-next:focus .ant-pagination-item-link,
/deep/.ant-pagination-prev:hover .ant-pagination-item-link,
/deep/.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #08a782;
  .anticon {
    color: #08a782;
  }
}
</style>
