<template>
  <a-layout class="main__container">
    <div class="banner">
      <a-carousel>
        <div class="item">
          <img src="../assets/home/fj1.webp" alt="" />
          <div class="tit">
            数字艺术家以月光为灵感，创作震撼3D光影雕塑，革新视觉艺术边界
          </div>
        </div>
        <div class="item">
          <img src="../assets/home/fj2.jpg" alt="" />
          <div class="tit">
            "跨界合作：时尚设计师与AI携手，打造未来感十足的虚拟时装秀"
          </div>
        </div>
      </a-carousel>
    </div>
    <div class="card">
      <a-tabs>
        <a-tab-pane key="1" tab="最新">
          <div class="list">
            <div
              class="item"
              v-for="(item, index) in 10"
              :key="index"
              :style="!infoActive.show1 && index > 5 ? 'display:none;' : ''"
              @click="detail()"
            >
              <img src="../assets/home/fj1.webp" alt="" />
              <div class="ri">
                <div class="tit">
                  这是一个标题，什么是活动，什么是设计什么是的标题
                </div>
                <div class="txt">
                  杭州某某主办单这是一个标题，什么是活动，什么是设计什么是设计什位；
                  杭州某某主办单这是一个标题，什么是活动，什么是设计什么是设计什位；
                  杭州某某主办单这是一个标题，什么是活动，什么是设计什么是设计什位；
                </div>
              </div>
            </div>
          </div>
          <div
            class="showAll"
            :class="!infoActive.show1 ? '' : 'active'"
            @click="infoActive.show1 = !infoActive.show1"
          >
            更多资讯
            <img src="../assets/home/icon_img26.png" alt="" />
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="美育要闻">
          <div class="list">
            <div
              class="item"
              v-for="(item, index) in 10"
              :key="index"
              :style="!infoActive.show2 && index > 5 ? 'display:none;' : ''"
              @click="detail()"
            >
              <img src="../assets/home/fj1.webp" alt="" />
              <div class="ri">
                <div class="tit">
                  这是一个标题，什么是活动，什么是设计什么是的标题
                </div>
                <div class="txt">
                  杭州某某主办单这是一个标题，什么是活动，什么是设计什么是设计什位；
                  杭州某某主办单这是一个标题，什么是活动，什么是设计什么是设计什位；
                  杭州某某主办单这是一个标题，什么是活动，什么是设计什么是设计什位；
                </div>
              </div>
            </div>
          </div>
          <div
            class="showAll"
            :class="!infoActive.show2 ? '' : 'active'"
            @click="infoActive.show2 = !infoActive.show2"
          >
            更多资讯
            <img src="../assets/home/icon_img26.png" alt="" />
          </div>
        </a-tab-pane>
        <a-tab-pane key="3" tab="学会动态">
          <div class="list">
            <div
              class="item"
              v-for="(item, index) in 10"
              :key="index"
              :style="!infoActive.show3 && index > 5 ? 'display:none;' : ''"
              @click="detail()"
            >
              <img src="../assets/home/fj1.webp" alt="" />
              <div class="ri">
                <div class="tit">
                  这是一个标题，什么是活动，什么是设计什么是的标题
                </div>
                <div class="txt">
                  杭州某某主办单这是一个标题，什么是活动，什么是设计什么是设计什位；
                  杭州某某主办单这是一个标题，什么是活动，什么是设计什么是设计什位；
                  杭州某某主办单这是一个标题，什么是活动，什么是设计什么是设计什位；
                </div>
              </div>
            </div>
          </div>
          <div
            class="showAll"
            :class="!infoActive.show3 ? '' : 'active'"
            @click="infoActive.show3 = !infoActive.show3"
          >
            更多资讯
            <img src="../assets/home/icon_img26.png" alt="" />
          </div>
        </a-tab-pane>
        <a-tab-pane key="4" tab="通知公告">
          <div class="list">
            <div
              class="item"
              v-for="(item, index) in 10"
              :key="index"
              :style="!infoActive.show4 && index > 5 ? 'display:none;' : ''"
              @click="detail()"
            >
              <img src="../assets/home/fj1.webp" alt="" />
              <div class="ri">
                <div class="tit">
                  这是一个标题，什么是活动，什么是设计什么是的标题
                </div>
                <div class="txt">
                  杭州某某主办单这是一个标题，什么是活动，什么是设计什么是设计什位；
                  杭州某某主办单这是一个标题，什么是活动，什么是设计什么是设计什位；
                  杭州某某主办单这是一个标题，什么是活动，什么是设计什么是设计什位；
                </div>
              </div>
            </div>
          </div>
          <div
            class="showAll"
            :class="!infoActive.show4 ? '' : 'active'"
            @click="infoActive.show4 = !infoActive.show4"
          >
            更多资讯
            <img src="../assets/home/icon_img26.png" alt="" />
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
    <div class="card_two">
      <div class="tit">
        <div class="info">
          <div class="name">
            美育视角
          </div>
          <div class="en">
            Aesthetic perspective
          </div>
        </div>
      </div>
      <div class="list">
        <div class="list_c">
          <div
            class="item"
            v-for="(item, index) in 12"
            :key="index"
            :style="!infoActive.show5 && index > 7 ? 'display:none;' : ''"
          >
            <img src="../assets/home/fj4.jpg" alt="" />
            <div class="item-t">
              这是一个标题，什么是活动，什么是设计什么是的标题
            </div>
          </div>
        </div>
        <div
          class="showAll"
          :class="!infoActive.show5 ? '' : 'active'"
          @click="infoActive.show5 = !infoActive.show5"
        >
          更多资讯
          <img src="../assets/home/icon_img26.png" alt="" />
        </div>
      </div>
    </div>
  </a-layout>
</template>

<script>
export default {
  name: "Information",
  data() {
    return {
      infoActive: {
        show1: false,
        show2: false,
        show3: false,
        show4: false,
        show5: false
      }
    };
  },
  methods: {
    detail() {
        this.$router.push({
            name:'InformationDetail'
        })
    }
  }
};
</script>

<style scoped lang="less">
.main__container {
  background-color: #eef0f3;
  padding-top: 72px;
  .banner {
    width: 1150px;
    margin: 0 auto;
    /deep/.slick-dots-bottom {
      bottom: 24px;
      display: flex !important;
      padding-left: 24px;
    }
    /deep/.slick-dots {
      li {
        button {
          width: 29px !important;
        }
      }
    }
    .item {
      width: 1150px;
      position: relative;
      height: 404px;
      img {
        width: 1150px;
        height: 404px;
      }
      .tit {
        position: absolute;
        left: 24px;
        bottom: 36px;
        color: #fff;
        font-size: 22px;
        right: 24px;
      }
    }
  }
  /deep/.ant-tabs-bar {
    margin: 0;
  }
  /deep/.ant-tabs-nav .ant-tabs-tab {
    font-size: 16px;
    padding: 4px 4px 16px;
    margin-top: 38px;
    &:hover {
      color: #ff8807;
    }
  }
  /deep/.ant-tabs-nav .ant-tabs-tab-active {
    color: #ff8807;
  }
  /deep/.ant-tabs-ink-bar {
    background-color: #ff8807;
  }
  .card {
    width: 1150px;
    margin: 0 auto;
    .list {
      padding: 0 8px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        margin-top: 36px;
        display: flex;
        width: 558px;
        cursor: pointer;
        img {
          width: 248px;
          height: 128px;
          object-fit: cover;
          margin-right: 16px;
        }
        .ri {
          flex: 1;
          .tit {
            height: 52px;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            display: -webkit-box;
            -webkit-line-clamp: 2; /* 设置显示的行数 */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .txt {
            margin-top: 14px;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.45);
            display: -webkit-box;
            -webkit-line-clamp: 3; /* 设置显示的行数 */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .showAll {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    margin: 40px auto 100px;
    width: 150px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
      margin-left: 6px;
    }
    &.active {
      img {
        transform: rotate(180deg);
      }
    }
  }
  .card_two {
    width: 1150px;
    margin: 0 auto;
    .tit {
      width: 1150px;
      height: 124px;
      background: #e86fc3;
      display: flex;
      align-items: center;
      justify-content: center;
      .info {
        .name {
          text-align: center;
          font-weight: 600;
          font-size: 24px;
          color: rgba(0, 0, 0, 0.85);
          padding-bottom: 6px;
          border-bottom: 4px solid #9ae486;
        }
        .en {
          font-weight: 600;
          font-size: 18px;
          color: rgba(0, 0, 0, 0.85);
          margin-top: 6px;
        }
      }
    }
    .list {
      width: 1150px;
      margin: 0 auto 100px;
      .list_c {
        margin-left: -34px;
        display: flex;
        flex-wrap: wrap;
        .item {
          width: 262px;
          margin-left: 34px;
          margin-top: 48px;
          img {
            width: 262px;
            height: 330px;
            object-fit: cover;
          }
          .item-t {
            margin-top: 12px;
            font-weight: 600;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
            display: -webkit-box;
            -webkit-line-clamp: 2; /* 设置显示的行数 */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
</style>
