<template>
  <a-layout class="main__container">
    <div class="banner">
      <img src="../assets/summarize/summarize_img1.png" alt="" />
      <div class="bom">
        <div class="its" v-for="(item, index) in 56" :key="index"></div>
      </div>
    </div>
    <div class="content">
      <div class="tab">
        <a-tabs default-active-key="1">
          <a-tab-pane key="1" tab="学会概况">
            <div class="line"></div>
            <div class="card_one">
              <div class="tit">
                <img src="../assets/summarize/summarize_img2.png" alt="" />
                <div class="name">
                  <div class="name_t">学会宗旨</div>
                  <div class="name_b">ABOUT THE SOCIETY</div>
                </div>
              </div>
              <div class="com">
                <p class="txt">
                  中国少年儿童造型艺术学会成立于1992年，是文旅部主管的国家一级学会,以促进中国少年儿童
                  审美教育的健康发展为宗旨，汇集国内美育行业专家、机构等一流学术力量，组织广大少年儿童及美
                  育工作者开展全国性和国际间的比赛、展览和学术交流等纯公益性艺术活动。
                </p>
                <p class="txt">
                  为落实育部《关于开展体育美育浸润行动计划的通知》，学会为中小学校美育课程教学、社团活
                  动、校园文化建设、教师培训等提供持续性的定向精准帮扶和志愿服务，推动中小学美育日常化、多
                  样化、特色化发展，切实提高教学水平和教育质量,
                  努力让每一个学生都能享有公平而有质量的美育
                  教育，积极有效地推动中国少年儿童美育事业的发展。
                </p>
                <div class="list">
                  <img src="../assets/summarize/summarize_img3.png" alt="" />
                  <div class="text">
                    <div class="label">终身名誉会长：</div>
                  </div>
                  <div class="text">
                    <div class="label">杨景芝</div>
                    <div class="value">
                      <div class="its">
                        中国少年儿童造型艺术学会终身名誉会长
                      </div>
                      <div class="its">中国美术家协会儿童美术艺委会秘书长</div>
                      <div class="its">
                        首都师范大学知名教授、著名少儿美术教育专家
                      </div>
                    </div>
                  </div>
                  <div class="text">
                    <div class="label">名誉会长：</div>
                  </div>
                  <div class="text">
                    <div class="label">杨力舟</div>
                    <div class="value">
                      <div class="its">中国美术家协会副会长</div>
                      <div class="its">中国美术馆前馆长、著名画家、书法家</div>
                      <div class="its">中国少年儿童造型艺术学会名誉会长</div>
                    </div>
                  </div>
                  <div class="text">
                    <div class="label">会长：</div>
                  </div>
                  <div class="text">
                    <div class="label">杨红</div>
                    <div class="value">
                      <div class="its">
                        广东省美术家协会少儿艺术委员会副主任
                      </div>
                      <div class="its">
                        杨之光美术中心总部校长、著名少儿美术教育专家
                      </div>
                    </div>
                  </div>
                  <div class="text">
                    <div class="label">副会长：</div>
                  </div>
                  <div class="text">
                    <div class="label">左志丹</div>
                    <div class="value">
                      <div class="its">中国青少年宫协会美术专业委员会会长</div>
                      <div class="its">全国校外少儿美术教育工作室联盟主任</div>
                    </div>
                  </div>
                  <div class="text">
                    <div class="label">曲涛</div>
                    <div class="value">
                      <div class="its">中国美术家协会江西省少儿艺委会主任</div>
                      <div class="its">中国美术家协会会员</div>
                    </div>
                  </div>
                  <div class="text">
                    <div class="label">秘书长兼法定代表人：</div>
                  </div>
                  <div class="text">
                    <div class="label">路海燕</div>
                    <div class="value">
                      <div class="its">中国美院传媒动画学院网游系主任</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bom">
                <img src="../assets/summarize/summarize_img4.png" alt="" />
              </div>
            </div>
            <div class="card_one">
              <div class="tit">
                <img src="../assets/summarize/summarize_img2.png" alt="" />
                <div class="name">
                  <div class="name_t">学会宗旨</div>
                  <div class="name_b">ABOUT THE SOCIETY</div>
                </div>
              </div>
              <div class="com">
                <p class="txt">
                  遵守中华人民共和国宪法、法律、法规和国家政策、遵守社会道德风尚，为积极贯彻“科教兴国”方针，促进我国少年儿童“审美教育”的健康发展，团结、组织会员积极开展各项学术活动，努力提高我国少年儿童造型艺术工作者的业务素质和工作效益，为弘扬和发展具有中国特色的少年儿童造型艺术事业，为促进社会主义精神文明建设和提高全民族文化艺术素质做贡献。
                </p>
              </div>
              <div class="end">
                <img src="../assets/summarize/summarize_img5.png" alt="" />
                <div class="end_t">
                  <div
                    class="its"
                    v-for="(item, index) in 56"
                    :key="index"
                  ></div>
                </div>
              </div>
            </div>
            <div class="card_two">
              <img
                class="bg"
                src="../assets/summarize/summarize_img6.png"
                alt=""
              />
              <div class="tit">
                <div class="name">
                  <div class="name_t">国家领导人和专家为学会题词</div>
                  <div class="name_b">NATIONAL LEADER'S INSCRIPTION</div>
                </div>
              </div>
              <div class="com">
                <div class="item" style="margin-left: 0;">
                  <img src="../assets/summarize/summarize_img7.png" alt="" />
                  <div class="txt">原全国人大副委员长</div>
                </div>
                <div class="item">
                  <img src="../assets/summarize/summarize_img8.png" alt="" />
                  <div class="txt">原全国人大副委员长</div>
                </div>
                <div class="item">
                  <img src="../assets/summarize/summarize_img9.png" alt="" />
                  <div class="txt">原中央委副主任</div>
                </div>
                <div class="item">
                  <img src="../assets/summarize/summarize_img10.png" alt="" />
                  <div class="txt">原政协副主席</div>
                </div>
                <div class="item" style="margin-left: 0;">
                  <img src="../assets/summarize/summarize_img11.png" alt="" />
                  <div class="txt">原政协副主席</div>
                </div>
                <div class="item">
                  <img src="../assets/summarize/summarize_img12.png" alt="" />
                  <div class="txt">原国务委员</div>
                </div>
                <div class="item">
                  <img src="../assets/summarize/summarize_img13.png" alt="" />
                  <div class="txt">原政协副主席</div>
                </div>
                <div class="item">
                  <img src="../assets/summarize/summarize_img14.png" alt="" />
                  <div class="txt">原政协副主席</div>
                </div>
              </div>
            </div>
            <div class="card_three">
              <div class="tit">
                <div class="name">
                  <div class="name_t">业务范围</div>
                  <div class="name_b">BUSINESS SCOPE</div>
                </div>
              </div>
              <div class="list">
                <div class="item">
                  1. 积极宣传、贯彻党和国家的教育方针和文艺政策；
                </div>
                <div class="item">
                  2. 团结社会各界力量，协力支持和促进我国少年儿童艺术事业发展；
                </div>
                <div class="item">
                  3.
                  在政府部门的指导和帮助下，努力认真做好各项有益于我国少年儿童艺术发展工作；
                </div>
                <div class="item">
                  4.
                  广泛、深入开展各项业务研究咨询、人才培养、师资培训、编辑出版各类学术著作和教学资料，为
                  全国少年儿童艺术教育及相关单位和组织提供服务；
                </div>
                <div class="item">
                  5.
                  组织全国少年儿童造型艺术学术研究成果和艺术作品评选活动，推出优秀少儿艺术人才；
                </div>
                <div class="item">
                  6. 开展国际间的少儿艺术交流活动和比赛。
                </div>
              </div>
              <div class="tit">
                <div class="name">
                  <div class="name_t">主要活动</div>
                  <div class="name_b">MAIN ACTIVITIES</div>
                </div>
              </div>
              <div class="list">
                <div class="item">
                  1.
                  广泛宣传学会的作用和意义，加强学会的管理，团结全国广大少年儿童造型艺术教育工作者，发展和
                  壮大会员队伍，共同促进我国少年儿童造型艺术事业的繁荣、发展。
                </div>
                <div class="item">
                  2.
                  创办学会会刊和网站，沟通信息，有效地全面凝聚广大会员的智慧和力量，开展广泛的学术交流活动。
                </div>
                <div class="item">
                  3.
                  组织全国性的学术研讨会，建立会员培训机制。编辑出版系列权威性学术著作和教材，积极促进和提
                  高教育教学和理论学术水平。
                </div>
                <div class="item">
                  4.
                  举办全国性和国际间的少年儿童造型艺术展览、比赛和评选活动，向全社会推出优秀少儿艺术人才、
                  模范教师和会员。
                </div>
                <div class="item">
                  5.
                  为确保学会的全面发展及各项业务活动的正常开展，学会将采取多种积极有效方式筹措经费，并由衷
                  希望全体会员和社会各界给予大力支持，为我们共同的事业作出积极贡献。
                </div>
              </div>
              <div class="bom">
                <div class="item">
                  <div class="item-t">组织机构</div>
                  <div class="item-c">
                    <div class="its">中国少年儿童造型艺术学会研究部</div>
                    <div class="its">中国少年儿童造型艺术学会培训部</div>
                    <div class="its">中国少年儿童造型艺术学会活动部</div>
                    <div class="its">中国少年儿童造型艺术学会公共关系部</div>
                    <div class="its">中国少年儿童造型艺术学会综合部</div>
                    <div class="its">中国少年儿童造型艺术学会人事保卫部</div>
                  </div>
                </div>
                <div class="item">
                  <div class="item-t">下属机构</div>
                  <div class="item-c">
                    <div class="its">
                      中国少年儿童造型艺术学会儿童绘画艺术委员会
                    </div>
                    <div class="its">
                      中国少年儿童造型艺术学会儿童书法艺术委员会
                    </div>
                    <div class="its">
                      中国少年儿童造型艺术学会儿童摄影艺术委员会
                    </div>
                    <div class="its">
                      中国少年儿童造型艺术学会儿童工艺艺术委员会
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="item-t">联系方式</div>
                  <div class="item-c">
                    <div class="its">官网：https://www.cacart.org.cn/</div>
                    <div class="its">邮箱：caca_1992@163.com</div>
                    <div class="its">
                      地址：北京市东城区灯市口大街33号国中商业大厦
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2" tab="学会章程">
            <div class="line"></div>
            <div class="card_four">
              <div class="tit">
                <img src="../assets/summarize/summarize_img2.png" alt="" />
                <div class="name">
                  <div class="name_t">学会章程</div>
                  <div class="name_b">THE ARTICLES OF ASSOCIATION</div>
                </div>
              </div>
              <div class="com">
                <div class="com_t">
                  中国少年儿童造型艺术学会章程
                </div>
                <div class="com_c">
                  <p style="margin-top: 48px;">第一章 总则</p>
                  <p>
                    第一条
                    本学会的名称为中国少年儿童造型艺术学会，英文译名为China
                    Association For Children Arts,英文缩写为CACA。
                  </p>
                  <p>
                    第二条
                    本学会是由全国从事少年儿童造型艺术研究、教学、管理、推广和热爱少年儿童艺术事业的有关人士和单位自愿结成的全国性、学术性、非营利性社会组织。
                  </p>
                  <p>
                    第三条
                    本学会的宗旨是：积极贯彻科教兴国方针，促进我国少年儿童审美教育的健康发展，团结、组织会员积极开展各项学术活动，努力提高我国少年儿童造型艺术工作者的业务素质和工作效率，为弘扬和发展具有中国特色的少年儿童造型艺术事业，为促进社会主义精神文明建设和提高全民族文化艺术素质做贡献。本学会遵守宪法、法律、法规和国家政策,践行社会主义核心价值观，弘扬爱国主义精神，遵守社会道德风尚，自觉加强诚信自律建设。
                  </p>
                  <p>
                    第四条
                    本学会坚持中国共产党的全面领导，根据中国共产党章程的规定，设立中国共产党的组织，开展党的活动，为党组织的活动提供必要条件。
                  </p>
                  <p>
                    第五条
                    本学会接受业务主管单位文化和旅游部和社团登记管理机关民政部的业务指导和监督管理。
                  </p>
                  <p>第六条 本学会的住所设在北京市。</p>
                  <p style="margin-top: 48px;">
                    第二章 业务范围 第七条 本学会的业务范围：
                  </p>
                  <p>
                    （一）积极宣传、贯彻党和国家的教育方针和文艺政策；
                  </p>
                  <p>
                    （二）通过书法、绘画、雕塑、摄影、动漫、工艺设计等视觉造型艺术的培训指导交流活动，提高我国少年儿童艺术修养、艺术鉴赏、艺术创作的综合能力；
                  </p>
                  <p>
                    （三）理论研究、学术交流、专业培训、国际合作、咨询服务；
                  </p>
                  <p>
                    （四）依据相关规定，编辑出版各类学术著作和教学资料，为全国少年儿童艺术教育及相关单位和组织提供服务；
                    业务范围中属于法律、法规、规章、规定须经批准的事项，依法经批准后开展。
                  </p>
                  <p style="margin-top: 48px;">第三章 会员</p>
                  <p>
                    第八条
                    本学会会员分为单位和个人会员。单位会员主要是各地区少年宫、少儿活动中心、小学校、幼儿园；个人会员主要由少年儿童艺术工作者和热爱少年儿童艺术事业的社会各界人士及优秀少儿艺术人才。
                  </p>
                  <p>第九条 申请加入本学会的会员，必须具备下列条件；</p>
                  <p></p>
                  <p>（一）拥护本学会的章程；</p>
                  <p>（二）有加入本学会的意愿；</p>
                  <p>（三）在本学会的业务范围内具有一定的影响。</p>
                  <p>第十条 会员入会程序：</p>
                  <p>（一）提交入会申请书；</p>
                  <p>（二）经理事会或常务理事会讨论通过；</p>
                  <p>（三）由理事会或理事会授权的机构发给会员证。</p>
                  <p>第十一条 会员享有下列权利：</p>
                  <p>（一）本学会的选举权、被选举权和表决权；</p>
                  <p>（二）参加本学会的活动；</p>
                  <p>（三）获得本学会服务的优先权；</p>
                  <p>（四）对本学会工作的批评建议权和监督权；</p>
                  <p>（五）入会自愿、退会自由。</p>
                  <p>第十二条 会员履行下列义务：</p>
                  <p>（一）遵守本学会的章程，执行本学会的决议；</p>
                  <p>（二）维护本学会的合法权益；</p>
                  <p>（三）完成本学会交办的工作；</p>
                  <p>（四）按规定交纳会费；</p>
                  <p>（五）向本学会反映情况、提供有关资料。</p>
                  <p>
                    第十三条
                    会员退会应书面通知本学会，并交回会员证。会员如果1年不交纳会费或不参加本学会活动的，视为自动退会。
                  </p>
                  <p>
                    第十四条
                    会员如有严重违反本章程的行为，经理事会或常务理事会表决通过，予以除名。
                  </p>
                  <p style="margin-top: 48px;">
                    第四章 组织机构和负责人产生、罢免
                  </p>
                  <p>
                    第十五条
                    本学会的最高权力机构是会员代表大会，会员代表大会的职权是：
                  </p>
                  <p>（一）制定和修改章程；</p>
                  <p>（二）选举和罢免理事；</p>
                  <p>（三）审议理事会的工作报告和财务报告；</p>
                  <p>（四）制定和修改会费标准；</p>
                  <p>（五）决定终止事宜；</p>
                  <p>（六）决定其他重大事宜。</p>
                  <p>
                    第十六条
                    会员代表大会须有2/3以上的会员代表出席方能召开，其决议须经到会会员代表半数以上表决通过方能生效。
                  </p>
                  <p>
                    第十七条
                    会员代表大会每届5年。因特殊情况需提前或延期换届的，须由理事会表决通过，报业务主管单位审查并经社团登记管理机关批准。延期换届最长不超过1年。
                  </p>
                  <p>
                    第十八条
                    理事会是会员代表大会的执行机构，在闭会其间领导本学会开展日常工作，对会员代表大会负责。
                  </p>
                  <p>第十九条 理事会的职权是：</p>
                  <p>（一）执行会员代表大会的决议；</p>
                  <p>
                    （二）选举和罢免会长、副会长、秘书长和常务理事；
                  </p>
                  <p>
                    （三）筹备召开会员代表大会；
                  </p>
                  <p>
                    （四）向会员代表大会报告工作和财务状况；
                  </p>
                  <p>
                    （五）决定会员的吸收和除名；
                  </p>
                  <p>
                    （六）决定办事机构、分支机构、代表机构和实体机构的设立、变更和终止；
                  </p>
                  <p>
                    （七）决定副秘书长、各机构主要负责人的聘任；
                  </p>
                  <p>
                    （八）领导本学会各机构开展工作；
                  </p>
                  <p>
                    （九）制定内部管理制度；
                  </p>
                  <p>
                    （十）决定其他重大事项。
                  </p>
                  <p>
                    第二十条
                    理事会须有2/3以上理事出席方能召开，其决议须经到会理事2/3以上表决通过方能生效。
                  </p>
                  <p>
                    第二十一条
                    理事会每年至少召开1次会议，情况特殊的，也可采用通讯形式召开。
                  </p>
                  <p>
                    第二十二条
                    本学会设立常务理事会，常务理事会由理事会选举产生，人数不超过理事人数的1/3。在理事会闭会期间行使第十九条第一、三、五、六、七、八、九项的职权，对理事会负责。
                  </p>
                  <p>
                    第二十三条
                    常务理事会须有2/3以上常务理事出席方能召开，其决议须经到会常务理事2/3以上表决通过方能生效。
                  </p>
                  <p>
                    第二十四条
                    常务理事会至少半年召开1次会议；情况特殊的也可采用通讯形式召开。
                  </p>
                  <p>
                    第二十五条 本学会的会长、副会长、秘书长必须具备下列条件：
                  </p>
                  <p>（一）坚持党的路线、方针、政策、政治素质好；</p>
                  <p>（二）在本学会业务领域内有较大影响；</p>
                  <p>（三）最高任职年龄不超过70周岁，秘书长为专职；</p>
                  <p>
                    （四）身体健康，能坚持正常工作；
                  </p>
                  <p>
                    （五）未受过剥夺政治权利的刑事处罚的；
                  </p>
                  <p>
                    （六）具有全民事行为能力。
                  </p>
                  <p>
                    第二十六条
                    本学会会长、副会长、秘书长如超过最高任职年龄的，须经理事会表决通过，报业务主管单位审查并社团登记机关批准后，方可任职。
                  </p>
                  <p>
                    第二十七条
                    本学会会长、副会长、秘书长每届任期5年，连任不得超过2届。因特殊情况需延长任期的，须经会员代表大会2/3以上会员代表表决通过，报业务主管单位审查并社团登记机关批准后，方可任职。
                  </p>
                  <p>
                    第二十八条
                    本学会秘书长为本学会法定代表人。法定代表人代表本学会签署有关重要文件。本学会法定代表人不得兼任其他学会的法定代表人。
                  </p>
                  <p>第二十九条 本学会会长行使下列职权：</p>
                  <p>（一）召集和主持理事会、常务理事会；</p>
                  <p>
                    （二）检查会员代表大会、理事会或常务理事会决议的落实情况。
                  </p>
                  <p>第三十条 本学会秘书长行使下列职权：</p>
                  <p>（一）主持办事机构开展日常工作，组织实施年度工作计划；</p>
                  <p>（二）协调各所属机构开展工作；</p>
                  <p>
                    （三）提名副秘书长以及各所属机构主要负责人，交理事或常务理事会决定；
                  </p>
                  <p>（四）决定各所属机构专职工作人员的聘用；</p>
                  <p>（五）处理其他日常事务。</p>
                  <p style="margin-top: 48px;">
                    第五章 资产管理、使用原则
                  </p>
                  <p>
                    第三十一条 本学会经费来源：
                  </p>
                  <p>
                    （一）会费；
                  </p>
                  <p>
                    （二）捐赠
                  </p>
                  <p>
                    （三）政府资助；
                  </p>
                  <p>
                    （四）在核准的业务范围内开展活动和提供服务的收入；
                  </p>
                  <p>
                    （五）利息；
                  </p>
                  <p>
                    （六）其他合法收入。
                  </p>
                  <p>
                    第三十二条 本学会按照国家有关规定收取会员会费。
                  </p>
                  <p>
                    第三十三条
                    本学会经费必须用于本章程规定的业务范围和事业的发展，不得在会员中分配。
                  </p>
                  <p>
                    第三十四条
                    本学会建立严格的财务管理制度，保证会计资料合法、真实、准确、完整。
                  </p>
                  <p>
                    第三十五条
                    本学会配备具有专业资格的会计人员。会计不得兼任出纳。会计人员必须进行会计核算，实行会计监督。会计人员调动工作或离职时，必须与接管人员办清交接手续。
                  </p>
                  <p>
                    第三十六条
                    本学会的资产管理必须执行国家规定的财务管理制度，接受会员代表大会和财政部门的监督。资产来源属于国家拨款或者社会捐赠、资助的，必须接受审计机关的监督，并将有关情况以适当方式向社会公布。
                  </p>
                  <p>
                    第三十七条 本学会换届或换法定代表人之前必须进行财务审计。
                  </p>
                  <p>
                    第三十八条
                    本学会的资产，任何单位、个人不得侵占、私分和挪用。
                  </p>
                  <p>
                    第三十九条
                    本学会专职工作人员的工资和保险、福利待遇，参照国家对事业单位的有关规定执行。
                  </p>
                  <p style="margin-top: 48px;">
                    第六章 章程的修改程序
                  </p>
                  <p>
                    第四十条
                    对本学会章程的修改，须经理事会表决通过后报会员代表大会审议。
                  </p>
                  <p>
                    第四十一条
                    本学会修改的章程，须在会员代表大会通过后15日内，经业务主管单位审查同意，并报社团登记管理机关核准后生效。
                  </p>
                  <p style="margin-top: 48px;">
                    第七章 终止程序及终止后的财产处理
                  </p>
                  <p>
                    第四十二条
                    本学会完成宗旨或自行解散或由于分立、合并等原因需要注销的，由理事会或常务理事会提出终止动议。
                  </p>
                  <p>
                    第四十三条
                    本学会终止动议须经会员代表大会表决通过，并报业务主管单位审查同意。
                  </p>
                  <p>
                    第四十四条
                    本学会终止前，须在业务主管单位及有关机关指导下成立清算组织，清算债权债务，处理善后事宜。清算期间，不开展清算以外的活动。
                  </p>
                  <p>
                    第四十五条
                    本学会经社团登记管理机关办理注销登记手续后即为终止。
                  </p>
                  <p>
                    第四十六条
                    本学会终止后的剩余财产，在业务主管单位和社团登记管理机关的监督下，按照国家有关规定，用于发展与本学会宗旨相关的事业。
                  </p>
                  <p style="margin-top: 48px;">
                    第八章 附则
                  </p>
                  <p>
                    第四十七条
                    本章程2021年04月23日第7届第1次会员代表大会表决通过。
                  </p>
                  <p>
                    第四十八条 本章程的解释权属本学会的理事会。
                  </p>
                  <p>
                    第四十九条 本章程自社团登记管理机关核准之日起生效。
                  </p>
                </div>
              </div>
              <div class="bom">
                <img src="../assets/summarize/summarize_img4.png" alt="" />
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </a-layout>
</template>

<script>
export default {
  name: "Summarize",
  data() {
    return {};
  }
};
</script>

<style scoped lang="less">
.main__container {
  background-color: #eef0f3;
  min-height: 100vh;
  padding-top: 72px;
  .banner {
    height: 420px;
    background: #f7f050;
    display: flex;
    justify-content: center;
    position: relative;
    img {
      width: 308px;
      height: 242px;
      margin-top: 123px;
    }
    .bom {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 1150px;
      margin-left: -575px;
      display: flex;
      justify-content: space-between;
      .its {
        width: 9px;
        height: 24px;
        background: #50b741;
      }
    }
  }
  .content {
    background: #fff7ff;
    /deep/.ant-tabs-bar {
      border: 0;
    }
    /deep/.ant-tabs-nav-wrap {
      margin-top: 58px;
      display: flex;
      justify-content: center;
      .ant-tabs-nav-scroll {
        height: 38px;
        .ant-tabs-nav {
          height: 36px;
          padding: 0;
          border: 1px solid #000;
          .ant-tabs-tab {
            padding: 0;
            width: 135px;
            text-align: center;
            font-size: 24px;
            margin: 0;
            font-weight: 300;
            height: 34px;
            line-height: 34px;
            &:hover {
              color: #1ddd7e;
            }
          }
          .ant-tabs-tab-active {
            background: #000000;
            color: #1ddd7e;
            &:before {
              display: none !important;
            }
          }
          .ant-tabs-ink-bar {
            display: none !important;
          }
        }
      }
    }
    .line {
      width: 740px;
      height: 3px;
      background-color: #000;
      margin: 32px auto;
    }
    .card_one {
      .tit {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 740px;
        margin: 36px auto;
        img {
          position: absolute;
          left: -39px;
          top: 0;
          width: 80px;
          height: 80px;
        }
        .name {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 80px;
          .name_t {
            font-size: 30px;
            color: #000;
            text-align: center;
            padding-bottom: 2px;
            border-bottom: 2px solid;
          }
          .name_b {
            text-align: center;
            color: #1ddd7e;
            font-size: 20px;
          }
        }
      }
      .txt {
        text-indent: 2rem;
        width: 740px;
        margin: 0 auto;
        line-height: 36px;
        color: #000;
        font-size: 16px;
      }
      .list {
        width: 368px;
        margin: 0 auto;
        position: relative;
        color: #000;
        font-size: 16px;
        line-height: 36px;
        img {
          position: absolute;
          left: -186px;
          top: 0;
          width: 50px;
          height: 50px;
        }
        .text {
          display: flex;
          width: 500px;
          .label {
            margin-right: 24px;
          }
        }
      }
      .bom {
        width: 740px;
        position: relative;
        margin: 0 auto;
        &:after {
          content: "";
          width: 740px;
          height: 3px;
          position: absolute;
          left: 0;
          top: 41px;
          background-color: #000;
        }
        img {
          width: 144px;
          height: 84px;
          display: block;
          margin: 0 auto;
        }
      }
      .end {
        width: 1150px;
        margin: 100px auto 0;
        img {
          width: 500px;
          height: 72px;
          display: block;
          margin: 0 auto;
        }
        .end_t {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          .its {
            width: 9px;
            height: 24px;
            background: #000;
          }
        }
      }
    }
    .card_two {
      position: relative;
      height: 1073px;
      background-color: #eef0f3;
      overflow: hidden;
      .bg {
        width: 1150px;
        height: 1073px;
        position: absolute;
        left: 50%;
        margin-left: -575px;
        top: 0;
      }
      .tit {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 740px;
        margin: 100px auto 0;
        position: relative;
        z-index: 2;
        .name {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 80px;
          .name_t {
            font-size: 30px;
            color: #000;
            text-align: center;
            padding-bottom: 2px;
            border-bottom: 2px solid;
          }
          .name_b {
            text-align: center;
            color: #1ddd7e;
            font-size: 20px;
          }
        }
      }
      .com {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        z-index: 2;
        width: 928px;
        margin: 30px auto;
        .item {
          margin-left: 64px;
          margin-top: 28px;
          img {
            width: 184px;
            height: 296px;
          }
          .txt {
            text-align: center;
            margin-top: 12px;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
    }
    .card_three {
      background-color: #eef0f3;
      padding-bottom: 100px;
      .tit {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 740px;
        padding: 36px 0;
        margin: 0px auto;
        .name {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 80px;
          .name_t {
            font-size: 30px;
            color: #000;
            text-align: center;
            padding-bottom: 2px;
            border-bottom: 2px solid;
          }
          .name_b {
            text-align: center;
            color: #1ddd7e;
            font-size: 20px;
          }
        }
      }
      .list {
        width: 746px;
        padding: 16px 0;
        margin: 0 auto;
        .item {
          line-height: 36px;
          font-size: 16px;
          color: #000;
        }
      }
      .bom {
        width: 746px;
        padding: 16px 0;
        margin: 0 auto;
        .item {
          line-height: 36px;
          font-size: 24px;
          color: #000;
          text-align: center;
          margin-top: 64px;
          .its {
            font-size: 18px;
          }
        }
      }
    }
    .card_four {
      padding-bottom: 24px;
      .tit {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 740px;
        margin: 36px auto;
        img {
          position: absolute;
          left: -39px;
          top: 0;
          width: 80px;
          height: 80px;
        }
        .name {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 80px;
          .name_t {
            font-size: 30px;
            color: #000;
            text-align: center;
            padding-bottom: 2px;
            border-bottom: 2px solid;
          }
          .name_b {
            text-align: center;
            color: #1ddd7e;
            font-size: 20px;
          }
        }
      }
      .com {
        width: 740px;
        margin: 40px auto;
        font-size: 16px;
        color: #000;
        .com_t {
          font-weight: 600;
        }
        .com_c {
        }
      }
      .bom {
        width: 740px;
        position: relative;
        margin: 0 auto;
        &:after {
          content: "";
          width: 740px;
          height: 3px;
          position: absolute;
          left: 0;
          top: 41px;
          background-color: #000;
        }
        img {
          width: 144px;
          height: 84px;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
}
</style>
