<template>
  <a-layout class="main__container">
    <div class="banner">
      <a-carousel arrows :dots="false">
        <div
          slot="prevArrow"
          class="custom-slick-arrow"
          style="zIndex: 1;bottom: 78px;width: 28px;height: 28px;"
        >
          <img src="../assets/home/left.png" alt="" />
        </div>
        <div
          slot="nextArrow"
          class="custom-slick-arrow"
          style="margin-left: 40px;bottom: 72px;width: 40px;height: 40px;"
        >
          <img src="../assets/home/right.png" alt="" />
        </div>
        <div>
          <img src="../assets/home/homepage-banner.png" alt="" />
        </div>
        <div><img src="../assets/home/homepage-banner.png" alt="" /></div>
      </a-carousel>
    </div>
    <div class="card_one">
      <div class="title">
        提升中国少年儿童具有创造美的能力
      </div>
      <div class="txt">
        全国性少年儿童造型艺术学术交流平台，团结各界力量，让每个学生都哟创造美的能力，推动少年儿童艺术视野的发展。
      </div>
      <div class="menu">
        <div class="item">
          <img src="../assets/home/icon_img1.png" alt="" />
          <div class="name">美创汇</div>
          <div class="text">一站式审美教育服务平台</div>
        </div>
        <div class="item">
          <img src="../assets/home/icon_img2.png" alt="" />
          <div class="name">关于学会</div>
          <div class="text">以造型艺术为范围的全国性社会团体组织</div>
        </div>
        <div class="item">
          <img src="../assets/home/icon_img3.png" alt="" />
          <div class="name">加入我们</div>
          <div class="text">为少年儿童造型艺术事业发展增添动力</div>
        </div>
      </div>
      <div class="bom">
        <img src="../assets/home/icon_img4.png" alt="" />
      </div>
    </div>
    <div class="card_two">
      <div class="card_left">
        <a-carousel>
          <div class="item">
            <img src="../assets/home/fj5.webp" alt="" />
            <div class="item_bom">
              2024年教育部“双减”政策发布
            </div>
          </div>
          <div class="item">
            <img src="../assets/home/login-centerBg.png" alt="" />
            <div class="item_bom">
              2022
            </div>
          </div>
        </a-carousel>
      </div>
      <div class="card_right">
        <a-tabs default-active-key="1">
          <a-tab-pane key="1" tab="最新">
            <div class="list">
              <div class="item active">
                <img src="../assets/home/icon_img5.png" alt="" />
                <div class="txt">
                  数字艺术家以月光为灵感，创作震撼3D光影雕塑，革新视觉艺术边界
                </div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  "跨界合作：时尚设计师与AI携手，打造未来感十足的虚拟时装秀"
                </div>
                <div class="date">7月10日</div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  "街头艺术新纪元：巨型互动壁画唤醒城市活力，市民参与创作热潮"
                </div>
                <div class="date">7月06日</div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  "古董与现代的碰撞：艺术家运用废旧机械零件，重塑经典艺术品形象"
                </div>
                <div class="date">6月10日</div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  "声音雕塑家：利用声波振动创造实体艺术，探索听觉与视觉的奇妙融合"
                </div>
                <div class="date">6月06日</div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  沙漠中的幻境：环保艺术家用回收材料构建巨型沙雕，呼吁关注地球危机"
                </div>
                <div class="date">5月10日</div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  "光影魔术师：青年艺术家运用激光与烟雾，呈现梦幻般的城市夜景"
                </div>
                <div class="date">4月10日</div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  "冰上芭蕾：极地艺术家挑战极限环境，雕刻冰上生命之树，见证自然奇迹"
                </div>
                <div class="date">3月10日</div>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2" tab="美育要闻">
            <div class="list">
              <div class="item active">
                <img src="../assets/home/icon_img5.png" alt="" />
                <div class="txt">
                  数字艺术家以月光为灵感，创作震撼3D光影雕塑，革新视觉艺术边界
                </div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  "跨界合作：时尚设计师与AI携手，打造未来感十足的虚拟时装秀"
                </div>
                <div class="date">7月10日</div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  "街头艺术新纪元：巨型互动壁画唤醒城市活力，市民参与创作热潮"
                </div>
                <div class="date">7月06日</div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  "古董与现代的碰撞：艺术家运用废旧机械零件，重塑经典艺术品形象"
                </div>
                <div class="date">6月10日</div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  "声音雕塑家：利用声波振动创造实体艺术，探索听觉与视觉的奇妙融合"
                </div>
                <div class="date">6月06日</div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  沙漠中的幻境：环保艺术家用回收材料构建巨型沙雕，呼吁关注地球危机"
                </div>
                <div class="date">5月10日</div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  "光影魔术师：青年艺术家运用激光与烟雾，呈现梦幻般的城市夜景"
                </div>
                <div class="date">4月10日</div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  "冰上芭蕾：极地艺术家挑战极限环境，雕刻冰上生命之树，见证自然奇迹"
                </div>
                <div class="date">3月10日</div>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="3" tab="学会动态">
            <div class="list">
              <div class="item active">
                <img src="../assets/home/icon_img5.png" alt="" />
                <div class="txt">
                  数字艺术家以月光为灵感，创作震撼3D光影雕塑，革新视觉艺术边界
                </div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  "跨界合作：时尚设计师与AI携手，打造未来感十足的虚拟时装秀"
                </div>
                <div class="date">7月10日</div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  "街头艺术新纪元：巨型互动壁画唤醒城市活力，市民参与创作热潮"
                </div>
                <div class="date">7月06日</div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  "古董与现代的碰撞：艺术家运用废旧机械零件，重塑经典艺术品形象"
                </div>
                <div class="date">6月10日</div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  "声音雕塑家：利用声波振动创造实体艺术，探索听觉与视觉的奇妙融合"
                </div>
                <div class="date">6月06日</div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  沙漠中的幻境：环保艺术家用回收材料构建巨型沙雕，呼吁关注地球危机"
                </div>
                <div class="date">5月10日</div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  "光影魔术师：青年艺术家运用激光与烟雾，呈现梦幻般的城市夜景"
                </div>
                <div class="date">4月10日</div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  "冰上芭蕾：极地艺术家挑战极限环境，雕刻冰上生命之树，见证自然奇迹"
                </div>
                <div class="date">3月10日</div>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="4" tab="通知公告">
            <div class="list">
              <div class="item active">
                <img src="../assets/home/icon_img5.png" alt="" />
                <div class="txt">
                  数字艺术家以月光为灵感，创作震撼3D光影雕塑，革新视觉艺术边界
                </div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  "跨界合作：时尚设计师与AI携手，打造未来感十足的虚拟时装秀"
                </div>
                <div class="date">7月10日</div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  "街头艺术新纪元：巨型互动壁画唤醒城市活力，市民参与创作热潮"
                </div>
                <div class="date">7月06日</div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  "古董与现代的碰撞：艺术家运用废旧机械零件，重塑经典艺术品形象"
                </div>
                <div class="date">6月10日</div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  "声音雕塑家：利用声波振动创造实体艺术，探索听觉与视觉的奇妙融合"
                </div>
                <div class="date">6月06日</div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  沙漠中的幻境：环保艺术家用回收材料构建巨型沙雕，呼吁关注地球危机"
                </div>
                <div class="date">5月10日</div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  "光影魔术师：青年艺术家运用激光与烟雾，呈现梦幻般的城市夜景"
                </div>
                <div class="date">4月10日</div>
              </div>
              <div class="item">
                <div class="dian"></div>
                <div class="txt">
                  "冰上芭蕾：极地艺术家挑战极限环境，雕刻冰上生命之树，见证自然奇迹"
                </div>
                <div class="date">3月10日</div>
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <div class="card_two_all">
      了解更多
      <img src="../assets/home/icon_img6.png" alt="" />
    </div>
    <div class="card_three">
      <div class="tit">
        <img src="../assets/home/icon_img7.png" alt="" />
      </div>
      <div class="com">
        <a-carousel arrows :dots="false">
          <div slot="prevArrow" class="custom-slick-arrow-left">
            <img src="../assets/home/icon_img8.png" alt="" />
          </div>
          <div slot="nextArrow" class="custom-slick-arrow-right">
            <img src="../assets/home/icon_img9.png" alt="" />
          </div>
          <div class="item">
            <div class="its">
              <img src="../assets/home/fj1.webp" alt="" />
              <div class="date">2021年10月02</div>
              <div class="name">
                直升机摄影师捕捉壮丽山川，展现地球最震撼的自然美景"
              </div>
            </div>
            <div class="its">
              <img src="../assets/home/fj2.jpg" alt="" />
              <div class="date">2021年10月02</div>
              <div class="name">摄像头下的艺术，开启微观世界的奇幻之旅"</div>
            </div>
            <div class="its">
              <img src="../assets/home/fj3.jpg" alt="" />
              <div class="date">2021年10月02</div>
              <div class="name">
                天文摄影师记录银河轨迹，定格夜空最动人的瞬间"
              </div>
            </div>
          </div>
          <div class="item">
            <div class="its">
              <img src="../assets/home/fj4.jpg" alt="" />
              <div class="date">2021年10月02</div>
              <div class="name">
                古迹修复专家用镜头讲述时光故事，古老建筑在光影中重生"
              </div>
            </div>
          </div>
        </a-carousel>
        <div class="all">
          了解更多
          <img src="../assets/home/icon_img10.png" alt="" />
        </div>
      </div>
    </div>
    <div class="card_four">
      <div class="tit">
        <img src="../assets/home/icon_img11.png" alt="" />
      </div>
      <div class="com">
        <img src="../assets/home/icon_img12.png" alt="" />
      </div>
      <div class="bom">
        <div class="txt">了解更多</div>
        <div class="img">
          <img src="../assets/home/icon_img6.png" alt="" />
        </div>
      </div>
    </div>
    <div class="card_five">
      <div class="tit">
        <div class="le">
          <img src="../assets/home/icon_img13.png" alt="" />
        </div>
        <div class="ri">
          <img src="../assets/home/icon_img14.png" alt="" />
        </div>
      </div>
      <div class="com">
        <div class="com_t">
          <img src="../assets/home/icon_img15.png" alt="" />
          <div class="name">
            <div class="name_t">中国少年儿童造型艺术学会</div>
            <div class="name_c">CHINA ASSOCIATION FOR CHILDRENIS ARTS</div>
          </div>
        </div>
        <div class="com_c">
          <div class="txt">
            中国少年儿童造型艺术学会成立于1992年，是文旅部主管的国家一级学会,以促进中国少年儿童审美教育的健康发展为宗旨，汇集国内美育行业专家、机构等一流学术力量，组织广大少年儿童及美育工作者开展全国性和国际间的比赛、展览和学术交流等纯公益性艺术活动。同时，开展为中小学校美育课程教学、社团活动、校园文化建设、教师培训等提供持续性的定向精准帮扶和志愿服务，推动中小学美育日常化、多样化、特色化发展，切实提高教学水平和教育质量,
            积极有效地推动中国少年儿童美育事业的发展。
          </div>
          <div class="ri">
            <img src="../assets/home/icon_img16.png" alt="" />
            <div class="text">扫码了解更多</div>
            <div class="btn">
              了解更多
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card_six">
      <div class="tit">
        <div class="le">
          <div class="le_t">
            <img src="../assets/home/icon_img17.png" alt="" />
            <div class="txt">艺术鉴赏</div>
          </div>
          <div class="le_b">
            <img src="../assets/home/icon_img18.png" alt="" />
          </div>
        </div>
        <div class="ri">
          <div class="txt">查看更多</div>
          <img src="../assets/home/icon_img19.png" alt="" />
        </div>
      </div>
      <div class="com">
        <div class="item">
          <img src="../assets/home/icon_img20.png" alt="" />
        </div>
        <div class="item">
          <img src="../assets/home/icon_img21.png" alt="" />
        </div>
        <div class="item">
          <img src="../assets/home/icon_img22.png" alt="" />
        </div>
      </div>
    </div>
  </a-layout>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {};
  }
};
</script>
<style scoped>
.banner .ant-carousel >>> .slick-slide {
  overflow: hidden;
  height: 450px;
}
.banner .ant-carousel >>> .slick-slide img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}
.banner .ant-carousel >>> .custom-slick-arrow {
  position: absolute;
  left: 50%;
  margin-left: -20px;
  top: inherit;
  cursor: pointer;
}
.banner .ant-carousel >>> .custom-slick-arrow img {
  width: 100%;
  height: 100%;
}
</style>
<style scoped lang="less">
.main__container {
  background-color: #eef0f3;
  min-height: 100vh;
  padding-top: 72px;
  .card_one {
    width: 935px;
    margin: 0 auto;
    .title {
      margin-top: 20px;
      font-size: 18px;
      text-align: center;
      color: #000;
    }
    .txt {
      margin-top: 8px;
      text-align: center;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 300;
    }
    .menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 28px;
      padding: 0 96px;
      .item {
        img {
          width: 100px;
          height: 100px;
          display: block;
          margin: 0 auto;
        }
        .name {
          margin-top: 8px;
          color: rgba(0, 0, 0, 0.85);
          text-align: center;
        }
        .text {
          font-weight: 300;
          margin-top: 8px;
          color: rgba(0, 0, 0, 0.85);
          text-align: center;
        }
      }
    }
    .bom {
      margin-top: 24px;
      img {
        width: 100%;
      }
    }
  }
  .card_two {
    display: flex;
    margin: 58px auto 0;
    .card_left {
      width: 512px;
      .item {
        img {
          width: 512px;
          height: 264px;
          object-fit: cover;
        }
        .item_bom {
          background-color: #e0e2e5;
          height: 76px;
          font-size: 20px;
          color: rgba(0, 0, 0, 0.85);
          text-align: center;
          padding: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border: 1px solid rgba(0, 0, 0, 0.12);
        }
      }
      /deep/.slick-dots {
        bottom: 18px;
        li {
          button {
            background-color: rgba(0, 0, 0, 0.2);
            width: 52px;
          }
        }
        .slick-active {
          button {
            background-color: #2f54eb;
          }
        }
      }
    }
    .card_right {
      margin-left: 24px;
      width: 500px;
      /deep/.ant-tabs-bar {
        margin: 0;
      }
      /deep/.ant-tabs-nav .ant-tabs-tab {
        font-size: 16px;
        padding: 4px;
        &:hover {
          color: #2f54eb;
        }
      }
      /deep/.ant-tabs-nav .ant-tabs-tab-active {
        color: #2f54eb;
        font-size: 24px;
      }
      /deep/.ant-tabs-ink-bar {
        background-color: #2f54eb;
      }
      .list {
        .item {
          cursor: pointer;
          display: flex;
          align-items: center;
          margin-top: 12px;
          &.active {
            font-size: 18px;
          }
          img {
            width: 28px;
            height: 16px;
          }
          .dian {
            width: 3px;
            height: 3px;
            border-radius: 2px;
            background-color: rgba(0, 0, 0, 0.85);
          }
          .txt {
            margin-left: 3px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            flex: 1;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
          }
          .date {
            color: #2f54eb;
            font-size: 16px;
          }
        }
      }
    }
  }
  .card_two_all {
    margin: 56px auto;
    width: 194px;
    height: 52px;
    background: #fff800;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
    img {
      width: 60px;
      height: 19px;
      margin-left: 8px;
    }
  }
  .card_three {
    width: 100%;
    background: #edd4ed;
    margin-top: 170px;
    .tit {
      display: flex;
      justify-content: center;
      margin-top: -76px;
      img {
        width: 1040px;
        height: 134px;
      }
    }
    .com {
      width: 1060px;
      margin: 58px auto;
      /deep/.slick-list {
        padding: 10px;
      }
      .custom-slick-arrow-left,
      .custom-slick-arrow-right {
        background-color: #fff;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        display: flex !important;
        align-items: center;
        justify-content: center;
        z-index: 9;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);
        img {
          width: 20px;
          height: 20px;
        }
      }
      .item {
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        .its {
          width: 316px;
          cursor: pointer;
          img {
            width: 316px;
            height: 400px;
            object-fit: cover;
            box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);
          }
          .date {
            margin-top: 12px;
            color: #000ee1;
            font-size: 12px;
          }
          .name {
            font-size: 21px;
            font-weight: 600;
            color: #000;
            margin-top: 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .all {
        margin: 56px auto;
        width: 194px;
        height: 52px;
        background: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        cursor: pointer;
        color: #fff84f;
        img {
          width: 60px;
          height: 19px;
          margin-left: 8px;
        }
      }
    }
  }
  .card_four {
    background-color: #edd4ed;
    .tit {
      width: 1200px;
      margin: 0 auto;
      position: relative;
      height: 164px;
      overflow: hidden;
      img {
        width: 1500px;
        position: absolute;
        left: 50%;
        margin-left: -750px;
      }
    }
    .com {
      img {
        width: 100%;
      }
    }
    .bom {
      margin: -50px auto 0;
      width: 256px;
      height: 50px;
      display: flex;
      cursor: pointer;
      .txt {
        background-color: #4f52ff;
        text-align: center;
        width: 128px;
        line-height: 50px;
        font-size: 18px;
      }
      .img {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff84f;
        width: 128px;
        height: 50px;
        img {
          width: 60px;
        }
      }
    }
  }
  .card_five {
    .tit {
      width: 1040px;
      margin: 98px auto 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .le {
        img {
          width: 276px;
        }
      }
      .ri {
        img {
          width: 88px;
        }
      }
    }
    .com {
      width: 1040px;
      background: #f282d0;
      margin: 0 auto;
      padding: 48px 77px;
      .com_t {
        display: flex;
        align-items: center;
        img {
          width: 50px;
          height: 50px;
        }
        .name {
          margin-left: 12px;
          .name_t {
            font-size: 18px;
            font-weight: 600;
          }
          .name_c {
            font-size: 14px;
            font-weight: 300;
            margin-top: 2px;
          }
        }
      }
      .com_c {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        .txt {
          font-size: 14px;
          width: 750px;
        }
        .ri {
          img {
            width: 75px;
            height: 75px;
            display: block;
            margin: 0 auto;
          }
          .text {
            font-size: 10px;
            text-align: center;
          }
          .btn {
            margin-top: 16px;
            background-color: #2bb4ae;
            width: 98px;
            height: 32px;
            color: #fff;
            cursor: pointer;
            text-align: center;
            line-height: 32px;
          }
        }
      }
    }
  }
  .card_six {
    width: 1040px;
    margin: 104px auto 200px;
    .tit {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .le {
        .le_t {
          display: flex;
          align-items: center;
          img {
            width: 128px;
            height: 36px;
          }
          .txt {
            margin-left: 16px;
            font-size: 30px;
            color: #000;
          }
        }
        .le_b {
          margin-top: 16px;
          img {
            width: 650px;
            height: 32px;
            display: block;
          }
        }
      }
      .ri {
        display: flex;
        align-items: center;
        border-bottom: 2px solid rgba(0, 0, 0, 0.85);
        padding: 12px 0;
        cursor: pointer;
        .txt {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          margin-right: 7px;
        }
        img {
          width: 49px;
          height: 10px;
        }
      }
    }
    .com {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 60px;
      .item {
        img {
          width: 330px;
          height: 520px;
          display: block;
        }
      }
    }
  }
}
</style>
