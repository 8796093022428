<template>
  <div class="main__container">
    <div class="content">
      <div class="tit">这是一个大大的标题，这是一个大大的标题</div>
      <div class="info">
        <div class="date">日期：2021年10月7日</div>
        <div class="name">撰稿人：赵文娜</div>
      </div>
      <div class="html" v-html="html"></div>
    </div>
    <div class="fixed">
      <div class="item" @click="scrollToTop()">
        <img src="../assets/home/icon_img27.png" alt="" />
      </div>
      <div
        class="item copy"
        style="border: 0"
        @click="copyText()"
        :data-clipboard-text="msg"
        data-clipboard-action="copy"
      >
        <img src="../assets/home/icon_img28.png" alt="" />
      </div>
    </div>
    <div class="bom">
      <div class="bom_t">
        其他推荐
      </div>
      <div class="bom_c">
        <div class="item" v-for="(item, index) in 4" :key="index">
          <img src="../assets/home/fj1.webp" alt="" />
          <div class="ri">
            <div class="tit">
              这是一个标题，什么是活动，什么是设计什么是的标题
            </div>
            <div class="txt">
              杭州某某主办单这是一个标题，什么是活动，什么是设计什么是设计什位；
              杭州某某主办单这是一个标题，什么是活动，什么是设计什么是设计什位；
              杭州某某主办单这是一个标题，什么是活动，什么是设计什么是设计什位；
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClipboardJS from "clipboard";
export default {
  name: "InformationDetail",
  data() {
    return {
      html: `<p>内容</p><br /><br /><br /><br /><br />
             <br /><br /><br /><br /><br /><br /><br />
             <br /><br /><br /><br /><br /><br /><br />
             <br /><br /><br />`,
      msg: process.env.VUE_APP_API_HOST + "/#/information-detail",
      clipboard: null,
      isCheck: true
    };
  },
  methods: {
    copyText() {
      this.clipboard = new ClipboardJS(`.copy`);
      this.clipboard.on("success", () => {
        if (this.isCheck) {
          this.isCheck = false;
          this.$message.success("复制成功");
          setTimeout(() => {
            this.isCheck = true;
          }, 100);
        }
      });
      this.clipboard.on("error", () => {
        if (this.isCheck) {
          this.isCheck = false;
          this.$message.error("复制失败");
          setTimeout(() => {
            this.isCheck = true;
          }, 100);
        }
      });
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth" // 可选参数，用于平滑滚动
      });
    }
  }
};
</script>

<style scoped lang="less">
.main__container {
  background-color: #eef0f3;
  padding-top: 72px;
  overflow: hidden;
  .content {
    width: 1150px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.02);
    background-color: #fff;
    margin: 38px auto 100px;
    padding: 40px 52px;
    .tit {
      font-weight: 600;
      font-size: 30px;
      color: rgba(0, 0, 0, 0.85);
    }
    .info {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.45);
      margin-top: 16px;
      margin-bottom: 40px;
      .name {
        margin-left: 36px;
      }
    }
  }
  .fixed {
    position: fixed;
    z-index: 99;
    right: 100px;
    top: 50%;
    width: 35px;
    height: 78px;
    border-radius: 18px;
    border: 1px solid #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    .item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      width: 35px;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  .bom {
    margin: 0 auto 100px;
    width: 1150px;
    .bom_t {
      font-weight: 500;
      font-size: 24px;
      color: rgba(0, 0, 0, 0.85);
    }
    .bom_c {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        margin-top: 36px;
        display: flex;
        width: 558px;
        cursor: pointer;
        img {
          width: 248px;
          height: 128px;
          object-fit: cover;
          margin-right: 16px;
        }
        .ri {
          flex: 1;
          .tit {
            height: 52px;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            display: -webkit-box;
            -webkit-line-clamp: 2; /* 设置显示的行数 */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .txt {
            margin-top: 14px;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.45);
            display: -webkit-box;
            -webkit-line-clamp: 3; /* 设置显示的行数 */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
</style>
