<template>
  <a-modal
    v-model="visible"
    :footer="null"
    :maskClosable="false"
    width="1200"
    :closable="false"
  >
    <div class="banner">
      <a-carousel arrows :dots="false" ref="carousel">
        <div slot="prevArrow" class="arrow-left">
          <a-icon type="left" style="font-size: 24px" />
        </div>
        <div slot="nextArrow" class="arrow-right">
          <a-icon type="right" style="font-size: 24px" />
        </div>
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="item_c">
            <div class="item_le">
              <img src="../assets/home/login-centerBg.png" alt="" />
            </div>
            <div class="item_ri">
              <div class="name">{{ item.name }}</div>
              <div class="info">
                <div class="its">
                  <div class="la">
                    <img src="../assets/home/icon_img47.png" alt="" />
                  </div>
                  <div class="vl">王铁柱</div>
                </div>
                <div class="its">
                  <div class="la">
                    <img src="../assets/home/icon_img48.png" alt="" />
                  </div>
                  <div class="vl">12</div>
                </div>
                <div class="its">
                  <div class="la">
                    <img src="../assets/home/icon_img49.png" alt="" />
                  </div>
                  <div class="vl">一等奖</div>
                </div>
                <div class="its">
                  <div class="la">
                    <img src="../assets/home/icon_img50.png" alt="" />
                  </div>
                  <div class="vl">中国</div>
                </div>
                <div class="its">
                  <div class="la">
                    <img src="../assets/home/icon_img51.png" alt="" />
                  </div>
                  <div class="vl">创作年代</div>
                </div>
              </div>
              <div class="txt">
                <div class="txt_t">
                  作品介绍
                </div>
                <div class="txt_c">
                  中国少年儿童造型艺术学会创办人之一，中国美术家协会少儿艺委常务理事，中国美术家协会会员，全国义务教育《美术》教材编委，悦少儿美术馆馆长阻理，少儿美术教育专
                </div>
              </div>
              <div class="bom">
                <div class="label">分享</div>
                <div class="menu">
                  <div class="its">
                    <img src="../assets/home/icon_img28.png" alt="" />
                  </div>
                  <div class="its">
                    <img src="../assets/home/icon_img42.png" alt="" />
                  </div>
                  <div class="its">
                    <img src="../assets/home/icon_img43.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-carousel>
      <div class="close" @click="close()">
        <a-icon type="close" />
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: "WorksRotation",
  data() {
    return {
      visible: false,
      strIndex: 0,
      list: []
    };
  },
  methods: {
    open(list, strIndex) {
      this.visible = true;
      this.list = list;
      this.strIndex = strIndex;
      this.$nextTick(() => {
        this.$refs.carousel.goTo(strIndex, true);
      });
    },
    close() {
      this.visible = false;
    }
  }
};
</script>

<style scoped lang="less">
/deep/.ant-modal-content {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  margin-top: -100px;
  .ant-modal-body {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: calc(100vh - 24px);
  }
  .banner {
    width: 1150px;
    height: 654px;
    margin: 0 auto;
    position: relative;
    .item {
      background-color: #fff;
      .item_c {
        display: flex;
        align-items: center;
        .item_le {
          width: 888px;
          height: 654px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 754px;
            height: 563px;
            object-fit: cover;
          }
        }
        .item_ri {
          border-left: 1px solid rgba(0, 0, 0, 0.06);
          height: 654px;
          width: 262px;
          position: relative;
          .name {
            padding: 0px 14px;
            margin-top: 36px;
            font-weight: 600;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.85);
            line-height: 28px;
            height: 56px;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* 设置显示的行数 */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .info {
            padding: 12px 14px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.06);
            .its {
              display: flex;
              align-items: center;
              margin-top: 14px;
              .la {
                margin-right: 8px;
                img {
                  width: 12px;
                  height: 12px;
                  display: block;
                }
              }
              .vl {
                font-weight: 400;
                font-size: 10px;
                color: rgba(0, 0, 0, 0.85);
              }
            }
          }
          .txt {
            padding: 20px 12px;
            .txt_t {
              font-weight: 500;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.85);
            }
            .txt_c {
              font-weight: 400;
              font-size: 12px;
              color: rgba(0, 0, 0, 0.65);
              margin-top: 12px;
            }
          }
          .bom {
            position: absolute;
            bottom: 0;
            height: 54px;
            display: flex;
            align-items: center;
            border-top: 1px solid rgba(0, 0, 0, 0.06);
            .label {
              margin-left: 29px;
              font-weight: 400;
              font-size: 12px;
              color: rgba(0, 0, 0, 0.85);
            }
            .menu {
              display: flex;
              align-items: center;
              margin-left: 25px;
              .its {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                cursor: pointer;
                margin-right: 18px;
                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }
        }
      }
    }
    .close {
      position: absolute;
      right: -85px;
      color: #fff;
      top: 29px;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .arrow-left {
    position: absolute;
    color: #fff;
    left: -50px;
  }
  .arrow-right {
    position: absolute;
    color: #fff;
    right: -50px;
  }
}
</style>
