<template>
  <a-layout class="main__container">
    <div class="card">
      <div class="le">
        <div class="video">
          <video
            :src="info.url"
            ref="myVideo"
            @canplay="onCanPlay"
            @play="onPlay"
            id="myVideo"
            controls
            @ended="onVideoEnded()"
          ></video>
          <div class="tit">{{ info.name }}</div>
        </div>
      </div>
      <div class="ri">
        <div class="tit">
          <div class="name">课程目录</div>
          <div class="tag">绘画</div>
        </div>
        <div class="tip">已完结/共10章</div>
        <div class="list">
          <div
            class="item"
            :class="item.id === info.id ? 'active' : ''"
            v-for="(item, index) in list"
            :key="index"
            @click="select(index)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="bom">
          <div class="bom_t">分享至</div>
          <div class="menu">
            <div
              class="item copy"
              :data-clipboard-text="msg"
              data-clipboard-action="copy"
              @click="copyText()"
            >
              <img src="../assets/home/icon_img28.png" alt="" />
            </div>
            <div class="item">
              <img src="../assets/home/icon_img42.png" alt="" />
            </div>
            <div class="item">
              <img src="../assets/home/icon_img43.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card_two">
      <div class="tit">
        <div class="name_z">
          课程推荐
        </div>
        <div class="name_en">
          Course recommendation
        </div>
        <div class="tip">
          具有社会特色、优质的公益性课程
        </div>
      </div>
      <div class="list">
        <div class="item" v-for="(item, index) in 6" :key="index">
          <div class="item_t">
            <img class="bg" src="../assets/home/fj3.jpg" alt="" />
            <img class="start" src="../assets/home/icon_img40.png" alt="" />
          </div>
          <div class="item_b">
            如何起一个简洁易懂的标题
          </div>
        </div>
      </div>
    </div>
  </a-layout>
</template>

<script>
import sp1 from "../assets/home/sp1.mp4";
import sp2 from "../assets/home/sp2.mp4";
import sp3 from "../assets/home/sp3.mp4";
import ClipboardJS from "clipboard";
export default {
  name: "PublicWelfareClassDetail",
  data() {
    return {
      info: {
        url: sp1,
        name: "视频1",
        id: "1",
        index: 0
      },
      list: [
        {
          url: sp1,
          name: "视频1",
          id: "1"
        },
        {
          url: sp2,
          name: "视频2",
          id: "2"
        },
        {
          url: sp3,
          name: "视频3",
          id: "3"
        },
        {
          url: sp1,
          name: "视频1",
          id: "4"
        },
        {
          url: sp2,
          name: "视频2",
          id: "5"
        },
        {
          url: sp3,
          name: "视频3",
          id: "6"
        },
        {
          url: sp1,
          name: "视频1",
          id: "7"
        },
        {
          url: sp2,
          name: "视频2",
          id: "8"
        },
        {
          url: sp3,
          name: "视频3",
          id: "9"
        },
        {
          url: sp1,
          name: "视频1",
          id: "10"
        },
        {
          url: sp2,
          name: "视频2",
          id: "11"
        },
        {
          url: sp3,
          name: "视频3",
          id: "12"
        },
        {
          url: sp1,
          name: "视频1",
          id: "13"
        },
        {
          url: sp2,
          name: "视频2",
          id: "14"
        },
        {
          url: sp3,
          name: "视频3",
          id: "15"
        }
      ],
      videoMuted: true,
      isCheck: true,
      msg: process.env.VUE_APP_API_HOST + "/#/public-welfare-class-detail"
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.myVideo.autoplay = true;
      this.$refs.myVideo.muted = true;
      this.$refs.myVideo.play().catch(error => {
        console.error("An error occurred while playing the video:", error);
      });
    });
  },
  methods: {
    copyText() {
      this.clipboard = new ClipboardJS(`.copy`);
      this.clipboard.on("success", () => {
        if (this.isCheck) {
          this.isCheck = false;
          this.$message.success("复制成功");
          setTimeout(() => {
            this.isCheck = true;
          }, 100);
        }
      });
      this.clipboard.on("error", err => {
        console.log(err);
        if (this.isCheck) {
          this.isCheck = false;
          this.$message.error("复制失败");
          setTimeout(() => {
            this.isCheck = true;
          }, 100);
        }
      });
    },
    onCanPlay() {
      this.$refs.myVideo.muted = false;
      this.videoMuted = false;
    },
    onPlay() {
      if (!this.videoMuted) {
        this.$refs.myVideo.volume = 1; // 设置音量
      }
    },
    onVideoEnded() {
      this.info = {
        ...this.list[this.info.index + 1],
        index: this.info.index + 1
      };
    },
    select(index) {
      this.info = {
        ...this.list[index],
        index: index
      };
    }
  }
};
</script>

<style scoped lang="less">
.main__container {
  background-color: #eef0f3;
  padding-top: 72px;
  .card {
    width: 1150px;
    background-color: #fff;
    margin: 24px auto;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    .le {
      width: 876px;
      height: 492px;
      position: relative;
      video {
        width: 876px;
        height: 492px;
      }
      .tit {
        position: absolute;
        color: #fff;
        left: 16px;
        top: 16px;
        right: 16px;
        font-weight: 600;
        font-size: 12px;
      }
    }
    .ri {
      width: 214px;
      .tit {
        background: rgba(224, 224, 224, 0.25);
        border-radius: 2px 2px 0 0;
        display: flex;
        padding: 16px 12px 0 12px;
        justify-content: space-between;
        align-items: center;
        .name {
          font-weight: 600;
          font-size: 20px;
          color: #08a782;
        }
        .tag {
          width: 48px;
          height: 20px;
          border-radius: 5px;
          border: 1px solid #fa891a;
          text-align: center;
          line-height: 18px;
          color: #fa891a;
          font-size: 12px;
        }
      }
      .tip {
        background: rgba(224, 224, 224, 0.25);
        font-weight: 400;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.45);
        padding: 6px 12px;
      }
      .list {
        overflow-y: auto;
        height: 300px;
        background: rgba(224, 224, 224, 0.25);
        border-radius: 0 0 2px 2px;
        padding: 12px;
        .item {
          margin-bottom: 10px;
          font-weight: 400;
          font-size: 12px;
          color: rgba(32, 32, 32, 0.45);
          cursor: pointer;
          padding: 3px 10px;
          &.active {
            background: #d2eae1;
            border-radius: 2px;
            font-size: 14px;
            color: #08a782;
            font-weight: 500;
          }
        }
      }
      .bom {
        margin-top: 32px;
        .bom_t {
          font-weight: 400;
          font-size: 12px;
          color: #000000;
          text-align: center;
        }
        .menu {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 12px;
          .item {
            width: 38px;
            height: 38px;
            background: rgba(0, 0, 0, 0.05);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;
            margin-top: 16px;
            img {
              height: 16px;
              width: 16px;
            }
          }
        }
      }
    }
  }

  .card_two {
    width: 920px;
    margin: 64px auto 100px;
    position: relative;
    z-index: 9;
    .tit {
      margin: 0 auto;
      width: 235px;
      text-align: center;
      .name_z {
        font-weight: 600;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.85);
      }
      .name_en {
        font-weight: 600;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.85);
        border-bottom: 2px solid;
      }
      .tip {
        font-weight: 300;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      margin-left: -64px;
      .item {
        margin-left: 64px;
        margin-top: 64px;
        width: 264px;
        .item_t {
          border-radius: 0px 0px 25px 0px;
          border: 1px solid #979797;
          width: 264px;
          height: 264px;
          position: relative;
          cursor: pointer;
          overflow: hidden;
          .bg {
            width: 100%;
            height: 100%;
            display: block;
          }
          .start {
            position: absolute;
            width: 58px;
            height: 58px;
            left: 50%;
            top: 50%;
            margin-left: -29px;
            margin-top: -29px;
            z-index: 2;
          }
        }
        .item_b {
          margin-top: 20px;
          font-weight: 500;
          font-size: 14px;
          color: #000000;
          display: -webkit-box;
          -webkit-line-clamp: 1; /* 设置显示的行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
