<template>
  <a-layout class="main__container">
    <div class="banner">
      <img src="../assets/home/icon_img29.png" alt="" />
    </div>
    <div class="card">
      <div class="tit">
        <div class="name">作品广场</div>
        <div class="en">The cooperation unit</div>
      </div>
      <div class="list">
        <div class="item" v-for="(item, index) in 20" :key="index" :style="!isShow&&index>11?'display:none;':''">
          <img src="../assets/home/fj2.jpg" alt="" />
          <div class="item_t">作品名称</div>
        </div>
      </div>
      <div
        class="showAll"
        :class="!isShow ? '' : 'active'"
        @click="isShow = !isShow"
      >
        更多作品
        <img src="../assets/home/icon_img26.png" alt="" />
      </div>
    </div>
  </a-layout>
</template>

<script>
export default {
  name: "ChildrenWorksDetail",
  data() {
    return {
      isShow: false
    };
  }
};
</script>

<style scoped lang="less">
.main__container {
  background-color: #eef0f3;
  padding-top: 72px;
  .banner {
    img {
      width: 100%;
    }
  }
  .card {
    width: 1150px;
    margin: 52px auto 32px;
    .tit {
      text-align: center;
      .name {
        font-weight: 600;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.85);
      }
      .en {
        font-weight: 600;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .list {
      margin-left: -34px;
      display: flex;
      flex-wrap: wrap;
      .item {
        margin-left: 34px;
        margin-top: 32px;
        width: 262px;
        cursor: pointer;
        img {
          display: block;
          width: 262px;
          height: 196px;
        }
        .item_t {
          width: 262px;
          height: 44px;
          background: rgba(0, 0, 0, 0.03);
          line-height: 44px;
          padding: 0 12px;
          font-weight: 500;
          font-size: 12px;
          color: #000000;
          display: -webkit-box;
          -webkit-line-clamp: 1; /* 设置显示的行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .showAll {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    margin: 40px auto 0px;
    width: 150px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
      margin-left: 6px;
    }
    &.active {
      img {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
