<template>
  <a-layout class="main__container">
    <div class="banner">
      <div class="cen">
        <div class="le">
          <div class="le_tit">
            <div class="name">艺术鉴赏</div>
            <div class="en">Art Appreciation</div>
            <div class="black"></div>
          </div>
          <div class="txt">
            展示古今中外世界名画、少儿美术活动作品、潮流创意艺术设计等优秀作品，为孩子提供欣赏、教育和知识。从引发好奇、探索发现、思考想象、重组归纳，进而带领孩子从从创造到创新，培养孩子具有认识美、体验美、感受美、欣赏美和创造美的能力，将美的思想传递给更多的孩子。
          </div>
        </div>
        <div class="ri">
          <img src="../assets/home/icon_img44.png" alt="" />
        </div>
      </div>
    </div>
    <div class="search">
      <div class="input">
        <input type="text" placeholder="请输入艺术家名字、艺术作品名称" />
      </div>
      <div class="btn">
        <img src="../assets/search.png" alt="" />
      </div>
    </div>
    <div class="card_one">
      <div class="tit">
        <div class="name">作品分类</div>
        <div class="en">Classification of works</div>
      </div>
      <div class="list">
        <div class="item" @click="detailType('ChildrenWorks')">
          <img src="../assets/home/icon_img20.png" alt="" />
        </div>
        <div class="item" @click="detailType('ThePaintings')">
          <img src="../assets/home/icon_img21.png" alt="" />
        </div>
        <div class="item" @click="detailType('TrendyArt')">
          <img src="../assets/home/icon_img22.png" alt="" />
        </div>
      </div>
    </div>
    <div class="card_two">
      <div class="tit">
        <div class="name">精选推荐</div>
        <div class="en">Selection of recommended</div>
      </div>
      <div class="list">
        <div
          class="item"
          v-for="(item, index) in 10"
          :key="index"
          @click="openModel(index)"
        >
          <img src="../assets/home/fj2.jpg" alt="" />
          <div class="info">
            <div class="name">这是那个作品的名字</div>
            <div class="tagList">
              <div class="tag">德国</div>
              <div class="tag">12岁</div>
              <div class="tag">1992年</div>
            </div>
            <div class="userName">作者名称</div>
          </div>
        </div>
      </div>
    </div>
      <WorksRotation ref="WorksRotation"></WorksRotation>
  </a-layout>
</template>

<script>
import WorksRotation from "@/components/WorksRotation.vue";

export default {
  name: "ArtAppreciation",
  components: { WorksRotation },
  data() {
    return {
      list: [
        {
          name: "这是那幅画的名字名字名字"
        },
        {
          name: "a1"
        },
        {
          name: "a2"
        },
        {
          name: "a3"
        },
        {
          name: "a4"
        },
        {
          name: "a5"
        },
        {
          name: "a6"
        },
        {
          name: "a7"
        },
        {
          name: "a8"
        },
        {
          name: "a9"
        }
      ]
    };
  },
  methods: {
    detailType(routerName) {
      this.$router.push({
        name: routerName
      });
    },
    openModel(index) {
      this.$refs.WorksRotation.open(this.list, index);
    }
  }
};
</script>

<style scoped lang="less">
.main__container {
  background-color: #eef0f3;
  padding-top: 72px;

  .banner {
    width: 100%;
    height: 420px;
    background: #f878a0;
    .cen {
      width: 1150px;
      height: 314px;
      margin: 52px auto 0;
      padding: 0 72px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .le {
        width: 387px;
        .le_tit {
          .name {
            font-weight: 600;
            font-size: 32px;
            color: rgba(0, 0, 0, 0.85);
          }
          .en {
            font-weight: 500;
            font-size: 18px;
            color: rgba(0, 0, 0, 0.85);
          }
          .black {
            width: 62px;
            height: 6px;
            background: rgba(0, 0, 0, 0.85);
            margin-top: 16px;
          }
        }
        .txt {
          margin-top: 12px;
          font-weight: 400;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 24px;
        }
      }
      .ri {
        img {
          width: 338px;
          height: 314px;
          display: block;
        }
      }
    }
  }
  .search {
    display: flex;
    align-items: center;
    margin: 48px auto 36px;
    .input {
      width: 651px;
      height: 36px;
      border: 1px solid #252525;
      input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        background-color: transparent;
        padding: 0 16px;
      }
    }
    .btn {
      width: 36px;
      height: 36px;
      border: 1px solid #252525;
      border-left: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .card_one {
    width: 1150px;
    margin: 0 auto;
    .tit {
      .name {
        font-weight: 600;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.85);
      }
      .en {
        font-weight: 500;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.45);
      }
    }
    .list {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .item {
        img {
          cursor: pointer;
          border-radius: 20px;
          width: 330px;
          height: 520px;
        }
      }
    }
  }
  .card_two {
    width: 1150px;
    margin: 100px auto;
    .tit {
      .name {
        font-weight: 600;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.85);
      }
      .en {
        font-weight: 500;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.45);
      }
    }
    .list {
      padding-top: 12px;
      display: flex;
      flex-wrap: wrap;
      margin-left: -12px;
      .item {
        width: 216px;
        margin-left: 12px;
        margin-top: 12px;
        cursor: pointer;
        img {
          width: 216px;
          height: 162px;
          display: block;
        }
        .info {
          padding: 16px;
          background-color: #fff;
          .name {
            font-weight: 500;
            font-size: 12px;
            color: #000000;
          }
          .tagList {
            margin-top: 8px;
            display: flex;
            align-items: center;
            .tag {
              height: 14px;
              background: #d8d8d8;
              border-radius: 7px;
              line-height: 14px;
              padding: 0 7px;
              font-size: 10px;
              color: rgba(0, 0, 0, 0.85);
              margin-right: 11px;
            }
          }
          .userName {
            font-weight: 500;
            font-size: 12px;
            color: #000000;
            margin-top: 8px;
          }
        }
      }
    }
  }
}
</style>
