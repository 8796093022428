<template>
  <a-layout class="main__container">
    <div class="banner">
      <div class="cen">
        <div class="le">
          <div class="le_tit">
            <div class="name">少儿作品</div>
            <div class="en">Children's works</div>
            <div class="black"></div>
          </div>
          <div class="txt">
            汇聚美育工作成果，展现少儿独特审美趣味与朝气蓬勃的精神面貌
          </div>
        </div>
        <div class="ri">
          <img src="../assets/home/icon_img44.png" alt="" />
        </div>
      </div>
    </div>
    <div class="card_one">
      <div class="tit">
        <img src="../assets/home/icon_img31.png" alt="" @click="back()" />
        <div class="tit-r">
          <div class="name">少儿作品</div>
          <div class="en">Children's works</div>
        </div>
      </div>
      <div class="list">
        <div
          class="item"
          v-for="(item, index) in 10"
          :key="index"
          :style="index > 3 && !isShow ? 'display:none' : ''"
          @click="detail()"
        >
          <img src="../assets/home/fj5.webp" alt="" />
          <div class="item_t">比赛标题</div>
        </div>
      </div>
    </div>
    <div
      class="showAll"
      :class="!isShow ? '' : 'active'"
      @click="isShow = !isShow"
    >
      更多作品
      <img src="../assets/home/icon_img26.png" alt="" />
    </div>
    <div class="card_two">
      <div class="tit">
        <div class="name">精选推荐</div>
        <div class="en">Selection of recommended</div>
      </div>
      <div class="list">
        <div class="item" v-for="(item, index) in 10" :key="index">
          <img src="../assets/home/fj2.jpg" alt="" />
          <div class="info">
            <div class="name">这是那个作品的名字</div>
            <div class="tagList">
              <div class="tag">德国</div>
              <div class="tag">12岁</div>
              <div class="tag">1992年</div>
            </div>
            <div class="userName">作者名称</div>
          </div>
        </div>
      </div>
    </div>

    <div class="side-operation">
      <img
        src="@/assets/intelligence/icon-close.png"
        alt=""
        class="side-icon"
        @click="onCloseQrCode"
        v-if="isShowQrCode"
      />
      <img
        src="@/assets/intelligence/icon-scan.png"
        alt=""
        class="side-icon"
        v-if="!isShowQrCode"
        @click="showQrCode"
      />
      <img
        src="@/assets/intelligence/icon-share.png"
        alt=""
        class="side-icon"
      />
      <img
        src="@/assets/intelligence/icon-top.png"
        alt=""
        class="side-icon"
        @click="onScrollTop"
      />

      <div class="qrcode" v-if="isShowQrCode">
        <div class="qrcode-container">
          <div class="qrcode-top">
            <div class="qrcode-item">
              <div class="qrcode-item-name">学会公众号</div>
              <img src="@/assets/intelligence/qrcode1.png" alt="" />
            </div>
            <div class="qrcode-item">
              <div class="qrcode-item-name">美创汇公众号</div>
              <img src="@/assets/intelligence/qrcode2.png" alt="" />
            </div>
          </div>
          <div class="qrcode-tip">扫码关注公众号，美育动态尽在掌握</div>
          <div class="qrcode-arrow"></div>
        </div>
      </div>
    </div>
  </a-layout>
</template>

<script>
export default {
  name: "ChildrenWorks",
  data() {
    return {
      isShow: false,
      visible: false,
      isShowQrCode: false
    };
  },
  methods: {
    back() {
      this.$router.back();
    },
    detail() {
      this.$router.push({
        name: "ChildrenWorksDetail"
      });
    },
    showQrCode() {
      this.visible = true;
      this.isShowQrCode = !this.isShowQrCode;
    },
    onCloseQrCode() {
      this.visible = false;
      this.isShowQrCode = !this.isShowQrCode;
    },
    // 回到顶部
    onScrollTop() {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth"
      });
    }
  }
};
</script>

<style scoped lang="less">
.main__container {
  background-color: #eef0f3;
  padding-top: 72px;

  .banner {
    width: 100%;
    height: 420px;
    background: #f878a0;

    .cen {
      width: 1150px;
      height: 314px;
      margin: 52px auto 0;
      padding: 0 72px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .le {
        width: 387px;

        .le_tit {
          .name {
            font-weight: 600;
            font-size: 32px;
            color: rgba(0, 0, 0, 0.85);
          }

          .en {
            font-weight: 500;
            font-size: 18px;
            color: rgba(0, 0, 0, 0.85);
          }

          .black {
            width: 62px;
            height: 6px;
            background: rgba(0, 0, 0, 0.85);
            margin-top: 16px;
          }
        }

        .txt {
          margin-top: 12px;
          font-weight: 400;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 24px;
        }
      }

      .ri {
        img {
          width: 338px;
          height: 314px;
          display: block;
        }
      }
    }
  }
  .card_one {
    width: 1150px;
    margin: 58px auto;
    .tit {
      display: flex;
      align-items: center;
      img {
        width: 24px;
        height: 24px;
        margin-right: 16px;
        cursor: pointer;
      }
      .tit-r {
        .name {
          font-weight: 500;
          font-size: 20px;
          color: #232324;
        }
        .en {
          font-weight: 500;
          font-size: 12px;
          color: rgba(35, 35, 36, 0.45);
        }
      }
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      margin-left: -18px;
      margin-top: 8px;
      .item {
        width: 566px;
        height: 382px;
        background: #e8e8e8;
        margin-left: 18px;
        margin-top: 20px;
        cursor: pointer;
        img {
          width: 566px;
          height: 318px;
          display: block;
        }
        .item_t {
          padding: 0 32px;
          font-weight: 600;
          font-size: 20px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 64px;
          display: -webkit-box;
          -webkit-line-clamp: 1; /* 设置显示的行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .showAll {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    margin: 40px auto 0px;
    width: 150px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
      margin-left: 6px;
    }
    &.active {
      img {
        transform: rotate(180deg);
      }
    }
  }
  .card_two {
    width: 1150px;
    margin: 100px auto;
    .tit {
      .name {
        font-weight: 600;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.85);
      }
      .en {
        font-weight: 500;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.45);
      }
    }
    .list {
      padding-top: 12px;
      display: flex;
      flex-wrap: wrap;
      margin-left: -12px;
      .item {
        width: 216px;
        margin-left: 12px;
        margin-top: 12px;
        cursor: pointer;
        img {
          width: 216px;
          height: 162px;
          display: block;
        }
        .info {
          padding: 16px;
          background-color: #fff;
          .name {
            font-weight: 500;
            font-size: 12px;
            color: #000000;
          }
          .tagList {
            margin-top: 8px;
            display: flex;
            align-items: center;
            .tag {
              height: 14px;
              background: #d8d8d8;
              border-radius: 7px;
              line-height: 14px;
              padding: 0 7px;
              font-size: 10px;
              color: rgba(0, 0, 0, 0.85);
              margin-right: 11px;
            }
          }
          .userName {
            font-weight: 500;
            font-size: 12px;
            color: #000000;
            margin-top: 8px;
          }
        }
      }
    }
  }

  .side-operation {
    width: 40px;
    height: 172px;
    background: #ffffff;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.06);
    border-radius: 24px;
    position: fixed;
    right: 80px;
    bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 18px 0;
    .side-icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    .qrcode {
      background-color: #fff;
      background-clip: padding-box;
      border-radius: 4px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
      position: fixed;
      right: 140px;
      bottom: 120px;
      .qrcode-container {
        position: relative;
        box-sizing: border-box;
        padding: 15px 33px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .qrcode-top {
          display: flex;
          .qrcode-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            .qrcode-item-name {
              font-weight: 600;
              font-size: 10px;
              color: rgba(0, 0, 0, 0.85);
              margin-bottom: 5px;
            }
            img {
              width: 61px;
              height: 61px;
            }
          }
          .qrcode-item:first-child {
            margin-right: 40px;
          }
        }
        .qrcode-tip {
          font-weight: 400;
          font-size: 9px;
          color: rgba(0, 0, 0, 0.85);
          margin-top: 6px;
        }
        .qrcode-arrow {
          position: absolute;
          display: block;
          width: 8.48528137px;
          height: 8.48528137px;
          background: transparent;
          border-style: solid;
          border-width: 4.24264069px;
          right: -4px;
          border-top-color: #fff;
          border-right-color: #fff;
          border-bottom-color: transparent;
          border-left-color: transparent;
          box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
        }
      }
    }
  }
}
</style>
