<template>
  <a-layout class="main__container">
    <div class="banner">
      <div class="cen">
        <div class="le">
          <div class="le_tit">
            <div class="name">世界名画</div>
            <div class="en">The paintings</div>
            <div class="black"></div>
          </div>
          <div class="txt">
            介绍美的知识，提供美的享受，传递美的精神，引发美的感悟
          </div>
        </div>
        <div class="ri">
          <img src="../assets/home/icon_img44.png" alt="" />
        </div>
      </div>
    </div>
    <div class="tabList">
      <div
        class="tab"
        :class="tabindex === 0 ? 'active' : ''"
        @click="setTab(0)"
      >
        <img src="../assets/home/icon_img45.png" alt="" class="img" />
        <img src="../assets/home/icon_img46.png" alt="" class="img2" />
        <div class="name">全部作品</div>
      </div>
      <div
        class="tab"
        :class="tabindex === 1 ? 'active' : ''"
        @click="setTab(1)"
      >
        <img src="../assets/home/icon_img45.png" alt="" class="img" />
        <img src="../assets/home/icon_img46.png" alt="" class="img2" />
        <div class="name">国画</div>
      </div>
      <div
        class="tab"
        :class="tabindex === 2 ? 'active' : ''"
        @click="setTab(2)"
      >
        <img src="../assets/home/icon_img45.png" alt="" class="img" />
        <img src="../assets/home/icon_img46.png" alt="" class="img2" />
        <div class="name">书法</div>
      </div>
      <div
        class="tab"
        :class="tabindex === 3 ? 'active' : ''"
        @click="setTab(3)"
      >
        <img src="../assets/home/icon_img45.png" alt="" class="img" />
        <img src="../assets/home/icon_img46.png" alt="" class="img2" />
        <div class="name">油画</div>
      </div>
      <div
        class="tab"
        :class="tabindex === 4 ? 'active' : ''"
        @click="setTab(4)"
      >
        <img src="../assets/home/icon_img45.png" alt="" class="img" />
        <img src="../assets/home/icon_img46.png" alt="" class="img2" />
        <div class="name">版画</div>
      </div>
      <div
        class="tab"
        :class="tabindex === 5 ? 'active' : ''"
        @click="setTab(5)"
      >
        <img src="../assets/home/icon_img45.png" alt="" class="img" />
        <img src="../assets/home/icon_img46.png" alt="" class="img2" />
        <div class="name">壁画</div>
      </div>
      <div
        class="tab"
        :class="tabindex === 6 ? 'active' : ''"
        @click="setTab(6)"
      >
        <img src="../assets/home/icon_img45.png" alt="" class="img" />
        <img src="../assets/home/icon_img46.png" alt="" class="img2" />
        <div class="name">素描</div>
      </div>
    </div>
    <div class="card_one">
      <div class="tit">
        <img src="../assets/home/icon_img31.png" alt="" @click="back()" />
        <div class="tit-r">
          <div class="name">世界名画</div>
          <div class="en">The world famous paintings</div>
        </div>
      </div>

      <div class="list">
        <div
          class="item"
          v-for="(item, index) in 20"
          :key="index"
          :style="!isShow && index > 11 ? 'display:none;' : ''"
        >
          <img src="../assets/home/fj2.jpg" alt="" />
          <div class="item_t">
            <div class="name">
              作品名称
            </div>
            <div class="tag">
              <div class="its">德国</div>
              <div class="its">王铁柱</div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="showAll"
        :class="!isShow ? '' : 'active'"
        @click="isShow = !isShow"
      >
        更多作品
        <img src="../assets/home/icon_img26.png" alt="" />
      </div>
    </div>
  </a-layout>
</template>

<script>
export default {
  name: "ThePaintings",
  data() {
    return {
      tabindex: 0,
      isShow: false
    };
  },
  methods: {
    setTab(index) {
      this.tabindex = index;
    },
    back() {
      this.$router.back();
    }
  }
};
</script>

<style scoped lang="less">
.main__container {
  background-color: #eef0f3;
  padding-top: 72px;

  .banner {
    width: 100%;
    height: 420px;
    background: #f878a0;

    .cen {
      width: 1150px;
      height: 314px;
      margin: 52px auto 0;
      padding: 0 72px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .le {
        width: 387px;

        .le_tit {
          .name {
            font-weight: 600;
            font-size: 32px;
            color: rgba(0, 0, 0, 0.85);
          }

          .en {
            font-weight: 500;
            font-size: 18px;
            color: rgba(0, 0, 0, 0.85);
          }

          .black {
            width: 62px;
            height: 6px;
            background: rgba(0, 0, 0, 0.85);
            margin-top: 16px;
          }
        }

        .txt {
          margin-top: 12px;
          font-weight: 400;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 24px;
        }
      }

      .ri {
        img {
          width: 338px;
          height: 314px;
          display: block;
        }
      }
    }
  }
  .tabList {
    margin: 48px auto 36px;
    display: flex;
    border-radius: 5px;
    overflow: hidden;
    background-color: #fff;
    .tab {
      width: 128px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
      .img2 {
        display: none;
      }
      .name {
        font-weight: 400;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.25);
      }
      &.active {
        background: linear-gradient(
          270deg,
          #faebeb 0%,
          rgba(249, 69, 118, 0.65) 100%
        );
        .img {
          display: none;
        }
        .img2 {
          display: block;
        }
        .name {
          font-weight: 600;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
  }
  .card_one {
    width: 1150px;
    margin: 58px auto;

    .tit {
      display: flex;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
        margin-right: 16px;
        cursor: pointer;
      }

      .tit-r {
        .name {
          font-weight: 500;
          font-size: 20px;
          color: #232324;
        }

        .en {
          font-weight: 500;
          font-size: 12px;
          color: rgba(35, 35, 36, 0.45);
        }
      }
    }

    .list {
      margin-left: -18px;
      display: flex;
      flex-wrap: wrap;
      .item {
        margin-left: 18px;
        margin-top: 18px;
        width: 274px;
        height: 328px;
        cursor: pointer;
        position: relative;
        img {
          display: block;
          width: 274px;
          height: 328px;
          object-fit: cover;
        }
        .item_t {
          width: 274px;
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 16px;
          .name {
            display: -webkit-box;
            -webkit-line-clamp: 1; /* 设置显示的行数 */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 600;
            font-size: 20px;
            color: #ffffff;
          }
          .tag {
            display: flex;
            .its {
              font-weight: 400;
              font-size: 14px;
              color: #ffffff;
              margin-right: 12px;
            }
          }
        }
      }
    }
  }
  .showAll {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    margin: 40px auto 0px;
    width: 150px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
      margin-left: 6px;
    }
    &.active {
      img {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
