import { render, staticRenderFns } from "./InformationDetail.vue?vue&type=template&id=dc7368f6&scoped=true"
import script from "./InformationDetail.vue?vue&type=script&lang=js"
export * from "./InformationDetail.vue?vue&type=script&lang=js"
import style0 from "./InformationDetail.vue?vue&type=style&index=0&id=dc7368f6&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc7368f6",
  null
  
)

export default component.exports