import { render, staticRenderFns } from "./WorksRotation.vue?vue&type=template&id=ddd2fef6&scoped=true"
import script from "./WorksRotation.vue?vue&type=script&lang=js"
export * from "./WorksRotation.vue?vue&type=script&lang=js"
import style0 from "./WorksRotation.vue?vue&type=style&index=0&id=ddd2fef6&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddd2fef6",
  null
  
)

export default component.exports